import React from "react";
import { toast } from "react-toastify";
import {
  ToastNotification,
  ErrorToastNotification,
} from "../components/ToastNotification";

const useToast = () => {
  const success = (message: string) => {
    toast(<ToastNotification message={message} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const error = (message: string) => {
    toast(<ErrorToastNotification message={message} />, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  return { success, error };
};

export default useToast;
