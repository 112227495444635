import React from "react";
import Text from "../Text";

const SuspenseLoader = () => {
  return (
    <div className="flex justify-center items-center h-[100vh] w-[100vw] overflow-hidden  bg-gray_300 bg-opacity-50">
      <div className="flex flex-col items-center">
        <img
          className=" w-auto animate-heartbeatToSpin"
          src="/simple_logo.svg"
          alt=""
        />
        <Text text="Please wait..." size={16} />
      </div>
    </div>
  );
};

export default SuspenseLoader;
