import React, { FC, ReactNode } from "react";
import Text from "../Text";

const LabelAndValue: FC<{
  label: string;
  value?: string | ReactNode;
  //   valueComponent?:
}> = ({ label, value }) => {
  return (
    <div className="flex flex-row py-[10px] items-center">
      <div className="w-[50%]">
        <Text
          text={label}
          size={14}
          color="text-[#252C32]"
          classNames="font-medium"
        />
      </div>

      <div className="w-[50%] flex-wrap flex-1 text-right">
        {typeof value === "string" ? (
          <Text
            text={value}
            size={14}
            color="text-[#252C32]"
            classNames="w-[100%] text-wrap break-words"
          />
        ) : (
          <div>{value}</div>
        )}
      </div>
    </div>
  );
};

export default LabelAndValue;
