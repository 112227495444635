import React, { useState } from "react";
import AuthVerificationOtp from "../../components/AuthVerificationOtp";
import {
  DASHBOARD,
  FORGOTPASSWORD,
  LOGIN,
  SET_NEW_PASSWORD,
  VERIFICATION,
} from "../../routes/navigations";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import api from "../../network/api";
import { IError } from "../../interface/error";
import useToast from "../../hooks/useToast";
import { ITwoFaLogin } from "../../interface/auth";
import { useAppDispatch } from "../../hooks/redux";
import {
  authenticate,
  clientRoles,
  setRole,
  setUser,
} from "../../redux/features/authSlice";
import { removeUnderscore } from "../../utils/utilMethods";

const Verification = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const toast = useToast();
  const state = useLocation().state as {
    from: string | null;
    email: string;
    password: string | null;
    authMode: string | null;
  };
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  const login2Factor = async () => {
    if (!otp) {
      toast.error("Enter your one time password");

      return;
    }
    if (!state?.email || !otp || !state?.password) {
      toast.error("Incomplete information to proceed.");
      return;
    }
    try {
      const response = await api.twoFactorLogin(
        {
          email: state.email,
          token: otp,
        }
        // { email: state.email, password: state.password }
      );

      const token = response.data?.token;
      const user = response.data?.user;

      if (user) {
        if (user.role.name === "PORTAL_ADMIN") throw new Error("Access denied");
        dispatch(setUser(user));
        dispatch(
          setRole(
            clientRoles[
              removeUnderscore(
                user.role?.name?.toLowerCase()
              ) as keyof typeof clientRoles
            ]
          )
        );
      }
      if (token) dispatch(authenticate(token));

      // navigate(DASHBOARD);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "Unable to login at the moment");
    }
    setLoading(false);
  };

  async function handleSubmit() {
    setLoading(true);
    switch (state?.from) {
      case FORGOTPASSWORD:
        setLoading(false);
        navigate(SET_NEW_PASSWORD, { state: { otp } });
        return;
      case LOGIN:
        login2Factor();
        return;
      default:
        navigate(DASHBOARD);
        return;
    }
  }

  async function resendOtp() {
    setLoading(true);
    try {
      if (!state.email) return;
      await api.resendOtp(state.email);
      toast.success("Another OTP has been sent to your email address");
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ??
          "Unable to resend OTP at the moment, please try again later"
      );
    }
    setLoading(false);
  }

  return (
    <div className="w-[100%] flex justify-center my-12">
      <div className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6">
        <AuthVerificationOtp
          title="Enter your verification code"
          description={
            state?.authMode === "EMAIL"
              ? `We just sent an OTP to ${state.email ? state.email : "your email"}, enter it below`
              : state?.authMode === "SMS"
                ? "We just sent an OTP to your registered phone number"
                : state?.authMode === "AUTH_APP"
                  ? "Enter the OTP from your authenticator app"
                  : ""
          }
          submitLabel="Login to your account"
          handleOtpChange={setOtp}
          handleSubmit={handleSubmit}
          loading={loading}
          resendOtp={resendOtp}
          showResend={state?.authMode !== "AUTH_APP"}
        />
        <Button
          variant="tertiary"
          label="Back to login"
          icon="arrow_left"
          iconPosition="left"
          onClick={() => navigate(LOGIN)}
        />
      </div>
    </div>
  );
};

export default Verification;
