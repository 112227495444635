import React, { FC } from "react";
import { Icon, IconProps } from "../Icons";
import Text from "../Text";
import Button from "../Button";

interface IEmptyState {
  icon: IconProps["name"];
  title: string;
  description: string;
  buttonText?: string;
  buttonAction?: () => void;
  customButton?: JSX.Element;
}
const EmptyState: FC<IEmptyState> = ({
  icon,
  title,
  description,
  buttonAction,
  buttonText,
  customButton,
}) => {
  return (
    <div className="h-[204px] flex flex-col items-center justify-center gap-8">
      <Icon name={icon} />
      <Text
        size={18}
        color="text-gray_900"
        classNames="font-medium"
        text={title}
      />
      <div className="w-[26%] text-center">
        <Text size={14} color="text-gray_500" text={description} />
      </div>
      <div className="mt-5">
        {buttonText && (
          <Button
            variant="primary"
            icon="add"
            label={buttonText}
            onClick={buttonAction}
          />
        )}
        {customButton && <>{customButton}</>}
      </div>
    </div>
  );
};
export default EmptyState;
