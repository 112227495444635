import React, { FormEvent, useEffect, useState } from "react";
import TextInput from "../../components/TextInput";
import { Icon } from "../../components/Icons";
import Text from "../../components/Text";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD } from "../../routes/navigations";
import { registrationAuthValidations } from "../../utils/validations";
import { useValidation } from "../../hooks/useValidation";
import useToast from "../../hooks/useToast";
import { IError } from "../../interface/error";
import api from "../../network/api";
import { IRegistrationAuth } from "../../interface/auth";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  authenticate,
  clearRegistrationDetails,
  clientRoles,
  selectRegistrationDetails,
  setRole,
  setUser,
} from "../../redux/features/authSlice";
import { removeUnderscore } from "../../utils/utilMethods";

const Createpassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const registrationDetails = useAppSelector(selectRegistrationDetails);
  const { data, addData, validated, validationResult } = useValidation(
    registrationAuthValidations
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (registrationDetails.token) {
      addData("token", registrationDetails.token);
    }
  }, [registrationDetails?.token]);

  async function submit() {
    setLoading(true);

    try {
      if (!registrationDetails.email) {
        return;
      }
      await api.registerAuth(data as IRegistrationAuth);
      const response = await api.login({
        email: registrationDetails.email,
        password: data.password,
      });

      const token = response.data?.token;
      const user = response.data?.user;
      dispatch(clearRegistrationDetails());
      if (token) dispatch(authenticate(token));
      if (user) {
        if (user.role.name === "PORTAL_ADMIN") throw new Error("Access denied");

        dispatch(setUser(user));
        dispatch(
          setRole(
            clientRoles[
              removeUnderscore(
                user.role?.name?.toLowerCase()
              ) as keyof typeof clientRoles
            ]
          )
        );
      }
      navigate(DASHBOARD);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "An error occured");
    }
    setLoading(false);
  }
  function handleChange(name: string, value: string) {
    addData(name, value);
  }

  return (
    <div className="w-[100%] flex justify-center my-12">
      <div className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6">
        <Icon name="create_password_icon" />
        <Text
          text="Create a password"
          size={30}
          color="text-gray_900"
          classNames="font-medium"
        />
        <Text
          text="Enter a secure password to protect your account"
          size={16}
          color="text-gray_500"
          classNames="-mt-[20px]"
        />

        <div className="flex flex-col mt-[8px] w-[100%] gap-5">
          <TextInput
            label="New Password"
            id="regNewPassword"
            placeholder="Enter new password"
            name="password"
            type="password"
            error={validationResult.password}
            onInputChange={handleChange}
          />
          <TextInput
            label="Confirm Password"
            id="regConfirmPassword"
            placeholder="Confirm new password"
            name="passwordConfirmation"
            type="password"
            error={validationResult.passwordConfirmation}
            onInputChange={handleChange}
          />

          <Button
            label="Go to dashboard"
            variant="primary_lg"
            loading={loading}
            onClick={submit}
            disabled={!validated}
          />
        </div>
      </div>
    </div>
  );
};

export default Createpassword;
