import React, { useEffect, useState } from "react";
import { Icon } from "../../components/Icons";
import Text from "../../components/Text";
import InfoAndAction from "../../components/infoAndAction";
import Otp from "../../components/Otp";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { CREATE_PASSWORD, LOGIN } from "../../routes/navigations";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  selectRegistrationDetails,
  setRegistrationDetails,
} from "../../redux/features/authSlice";
import api from "../../network/api";
import useToast from "../../hooks/useToast";
import { IError } from "../../interface/error";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();
  const registrationData = useAppSelector(selectRegistrationDetails);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");

  function onOtpChange(val: string) {
    setOtp(val);
  }
  function verify() {
    dispatch(setRegistrationDetails({ token: otp }));
    navigate(CREATE_PASSWORD);
  }

  async function resendOtp() {
    setLoading(true);
    try {
      if (!registrationData.email) return;
      await api.resendOtp(registrationData.email);
      toast.success("Another OTP has been sent to your email address");
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ??
          "Unable to resend OTP at the moment, please try again later"
      );
    }
    setLoading(false);
  }
  return (
    <div className="w-[100%] flex justify-center my-12">
      <div className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6">
        <Icon name="mail" />
        <Text
          text="Verify your email"
          size={30}
          color="text-gray_900"
          classNames="font-medium"
        />
        <Text
          text={`We just sent an OTP to ${registrationData?.email}, enter it below`}
          size={16}
          color="text-gray_500"
          classNames="-mt-[20px]"
        />

        <Otp length={6} onChange={onOtpChange} />

        <Button
          variant="primary_lg"
          label="Verify phone number"
          classNames="w-[100%]"
          loading={loading}
          onClick={verify}
          loadingText={loading ? "Verifying" : ""}
        />

        <InfoAndAction
          info="Didn’t receive an OTP?"
          actionText="Click to resend"
          action={resendOtp}
        />

        <Button
          variant="tertiary"
          label="Back to login"
          icon="arrow_left"
          iconPosition="left"
          disabled={!otp}
          onClick={() => navigate(LOGIN)}
        />
      </div>
    </div>
  );
};

export default VerifyEmail;
