import React, { CSSProperties, FC, useEffect, useRef, useState } from "react";
import Button from "../Button";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import Text from "../Text";
import moment from "moment";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import "../DatePicker/style.css";
import { IQuery } from "../../interface/network";
import { formatDate } from "../../utils/utilMethods";
import { useQuery } from "../../hooks/useQuery";

const DateRange: FC<{
  defaultStart?: string;
  defaultEnd?: string;
  callBack?: (query?: IQuery) => void;
  buttonStyle?: CSSProperties;
  onShow?: () => void;
  showIcon?: boolean;
}> = ({
  defaultEnd,
  defaultStart,
  callBack,
  buttonStyle,
  onShow,
  showIcon = true,
}) => {
  const query = useQuery();
  const defaultStartDate = query.get("startDate");
  const defaultEndDate = query.get("endDate");
  const [show, setShow] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    if (defaultEnd || defaultEndDate) {
      if (defaultEnd) {
        setEndDate(new Date(defaultEnd));
      } else if (defaultEndDate) {
        setEndDate(new Date(defaultEndDate));
      }
    }
    if (defaultStart || defaultStartDate) {
      if (defaultStart) {
        setStartDate(new Date(defaultStart));
      } else if (defaultStartDate) {
        setStartDate(new Date(defaultStartDate));
      }
    }
  }, [defaultEnd, defaultStart]);

  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const toggle = () => {
    setShow((prev) => {
      if (onShow && !prev) onShow();
      return !prev;
    });
  };
  const ref = useRef(null);

  useOnClickOutside(ref, () => setShow(false));
  const onCancel = () => {
    if (callBack) {
      callBack({
        page: 1,
        startDate: formatDate("2024-01-01", "YYYY-MM-DD"),
        endDate: formatDate(new Date(), "YYYY-MM-DD"),
      });
    }
    toggle();
  };
  const save = () => {
    if (callBack) {
      callBack({
        startDate: formatDate(startDate, "YYYY-MM-DD"),
        endDate: endDate ? formatDate(endDate, "YYYY-MM-DD") : "",
        page: 1,
      });
    }
    toggle();
  };

  return (
    <div className="relative dateRange" ref={ref}>
      <Button
        style={buttonStyle ? { ...buttonStyle } : {}}
        label="Select dates"
        icon={showIcon ? "calendar" : undefined}
        onClick={toggle}
      />
      {show && (
        <div className="absolute z-30 xs:-right-[182px] lg:-right-[25px]">
          <div className="flex flex-col bg-base_White shadow-xl py-[20px] rounded-lg overflow-y-hidden">
            <div className="flex flex-row px-0 ">
              <div>
                <ReactDatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  selectsStart
                  inline
                />
              </div>
              <div>
                <ReactDatePicker
                  selected={endDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  //   minDate={startDate}
                  selectsRange
                  selectsEnd
                  inline
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="p-[10px] flex flex-row items-center gap-3">
                <div className="h-[40px] w-[100px] px-10 flex flex-row items-center rounded-lg border border-gray_300 bg-base_White">
                  <Text
                    text={moment(startDate).format("MMM D, YYYY")}
                    color="text-gray_900"
                    size={12}
                  />
                </div>
                <div className="text-gray_500">-</div>
                <div className="h-[40px] w-[100px] px-10 flex flex-row items-center rounded-lg border border-gray_300 bg-base_White">
                  <Text
                    text={endDate ? moment(endDate).format("MMM D, YYYY") : ""}
                    color="text-gray_900"
                    size={12}
                  />
                </div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <div>
                  <Button label="Cancel" onClick={onCancel} />
                </div>
                <div>
                  <Button label="Apply" onClick={save} variant="primary" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRange;
