import React, { ChangeEvent, FC, useRef, useState } from "react";

interface props {
  length?: number;
  onChange: (pin: string) => void;
  otpValid?: boolean;
}

const Otp: FC<props> = ({ length = 5, onChange, otpValid }) => {
  const otpRef = useRef<Array<HTMLInputElement | null>>([]);
  const [otp, setOtp] = useState<Array<string>>([]);

  const switchFocus = (
    index: number,
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setOtp((otps) => {
      const newOtps = [...otps];
      newOtps[index] = e.target.value;
      return newOtps;
    });
    onChange(otpRef.current.map((otps) => otps?.value).join(""));

    if (e.target.value && index <= length - 1) {
      otpRef.current[index + 1]?.focus();
    } else if (index !== 0) {
      otpRef.current[index - 1]?.focus();
    }
  };

  const handleKeydown = (i: any, event: any): void => {
    const key = event.keyCode || event.charCode;
    if (key === 8 || key === 46) {
      if (!otp[i]) {
        otpRef.current[i - 1]?.focus();
      }
    }
  };

  return (
    <div className="flex gap-[12px] items-center">
      {new Array(length).fill("*").map((_, index) => (
        <input
          onChange={(e) => switchFocus(index, e)}
          onKeyDown={(e) => handleKeydown(index, e)}
          ref={(ref) => (otpRef.current[index] = ref)}
          maxLength={1}
          placeholder="-"
          type="tel"
          autoComplete="one-time-code"
          autoFocus={index === 0}
          value={otp[index] || ""}
          key={index}
          className="w-[60px] h-[64px] py-10 pb-[15px] text-center rounded-lg border border-gray_300 bg-base_White shadow-xs placeholder:text-gray_300 font-medium text-5xl text-primary_Blue_600 leading-[60px] -tracking-[0.96px] focus:outline-none  focus:border-primary_Blue_300 focus:shadow-xs"
        />
      ))}
    </div>
  );
};

export default Otp;
