import React, {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import { Icon } from "../Icons";
import Text from "../Text";

interface PhoneInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  /**
   * text | number| email
   *
   */
  type?: "text" | "number" | "email";
  id: string;
  name: string;
  error?: string;
  onInputChange?: (name: string, value: string) => void;
}
const PhoneInput: FC<PhoneInputProps> = ({
  label,
  type = "number",
  id,
  ...props
}) => {
  const Input = document.getElementById(id);

  const [inputValue, setInputValue] = useState<string>();

  useEffect(() => {
    if (Input && type === "number") {
      Input.addEventListener("wheel", preventInputScroll);
    }

    return () => {
      if (Input && type === "number")
        Input.removeEventListener("wheel", preventInputScroll);
    };
  }, []);

  useEffect(() => {
    // if (props.defaultValue) {
    setInputValue(props.defaultValue?.toString() ?? "");
    // }
  }, [props.defaultValue]);

  function preventInputScroll(e: MouseEvent) {
    e.preventDefault();
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    if (props.onInputChange) props.onInputChange(props.name, e.target.value);
    setInputValue(e.target.value);
  };
  return (
    <div className="relative">
      <label
        className="text-sm leading-[20px] text-gray_700 font-medium font-Euclid"
        htmlFor={id}
      >
        {label}
      </label>
      <div className="relative">
        <div className="absolute rounded left-2.5  top-3 overflow-hidden">
          <Icon name="nigeria" />
        </div>
        {props.error && (
          <div className="absolute  right-2.5  top-[15px]">
            <Icon name="input_error" />
          </div>
        )}
        <input
          className={`h-40 mt-1 shadow-xs disabled:bg-gray_50 bg-white rounded-lg pl-[50px] px-2.5 border ${props.error ? "border-error_300" : "border-gray_200"} focus:outline-0 focus:ring-0 focus:border focus:border-primary_Blue_300 focus:shadow-inputFocus text-gray_500 placeholder:font-normal placeholder:text-gray_300 w-[100%]`}
          id={id}
          name={props.name}
          type={type}
          onChange={handleChange}
          placeholder={props.placeholder ?? "Input text.."}
          defaultValue={props.defaultValue}
          value={inputValue}
          disabled={props.disabled}
        />
      </div>
      {props.error && (
        <div className="mt-[5px]">
          <Text text={props.error} size={14} color="text-error_500" />
        </div>
      )}
    </div>
  );
};

export default PhoneInput;
