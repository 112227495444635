import { requestMaker } from "./request";
import endpoints from "./endpoints";
import {
  IChangePassword,
  ICompany,
  IEnable2fa,
  ILogin,
  ILoginResponseData,
  IRegistration,
  IRegistrationAuth,
  IRegistrationAuthResponseData,
  IRegistrationResponseData,
  IRoleData,
  ITwoFaLogin,
  IUpdateUser,
} from "../interface/auth";
import { INetworkResponse, IQuery } from "../interface/network";
import {
  IAddDirectorResponse,
  IBusinessDetails,
  IDirectorData,
  IGetDirector,
} from "../interface/business";
import {
  IApproveRejectPayload,
  IGetUpload,
  IGetUploads,
  IResendUpload,
  IUpload,
  IUploadCommentsResponseData,
} from "../interface/upload";
import { IAddTeam, IGetTeamData, ITeamSettings } from "../interface/team";
import {
  IGetNotification,
  IGetNotificationSettingsData,
  IUpdateNotificationData,
} from "../interface/notification";
import {
  IActivateWalletData,
  ICountData,
  IDashboardCountResponseData,
  IWalletDashboardResponseData,
  IWalletDetailsData,
  IWalletReportResponseData,
  IWalletTransactionData,
} from "../interface/wallet";
import {
  ITransactionDetailsResponseData,
  ITransactionResponseData,
} from "../interface/transaction";

async function register(
  data: IRegistration
): Promise<INetworkResponse<IRegistrationResponseData>> {
  return requestMaker({
    type: "POST",
    route: endpoints.REGISTER,
    data: data,
  });
}

async function registerAuth(
  data: IRegistrationAuth
): Promise<INetworkResponse<IRegistrationAuthResponseData>> {
  return requestMaker({
    type: "POST",
    route: endpoints.REGISTER_AUTH,
    data: data,
  });
}

async function resendOtp(email: string): Promise<INetworkResponse<any>> {
  return requestMaker({
    type: "GET",
    route: endpoints.RESEND_OTP(email),
  });
}

async function login(
  data: ILogin
): Promise<INetworkResponse<ILoginResponseData>> {
  return requestMaker({
    type: "POST",
    route: endpoints.LOGIN,
    data: data,
  });
}

async function twoFactorLogin(
  data: ITwoFaLogin
  // loginData: ILogin
): Promise<INetworkResponse<ILoginResponseData>> {
  return requestMaker({
    type: "POST",
    route: endpoints.TWO_FA_LOGIN(data.email, data.token),
    // data: loginData,
  });
}

async function forgotPassword(
  email: string
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "GET",
    route: endpoints.FORGOT_PASSWORD(email),
  });
}

async function forgotPasswordActivation(
  token: string,
  password: string
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.FORGOT_PASSWORD_ACTIVATION(token),
    data: { password },
  });
}

async function changePassword(
  email: string,
  data: IChangePassword
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.CHANGE_PASSWORD(email),
    data,
  });
}

async function getUser(): Promise<ILoginResponseData["user"]> {
  return requestMaker({
    type: "GET",
    route: endpoints.GET_USER,
    isSecure: true,
  });
}

async function logout(): Promise<unknown> {
  return requestMaker({
    type: "POST",
    route: endpoints.LOGOUT,
    isSecure: true,
  });
}

async function getNotification(
  id: number
): Promise<Record<string, IGetNotification>> {
  return requestMaker({
    type: "GET",
    route: endpoints.GET_NOTIFICATION(id),
    isSecure: true,
  });
}

async function readNotification(
  notificationId: number
): Promise<IGetNotification> {
  return requestMaker({
    type: "GET",
    route: endpoints.READ_NOTIFICATION(notificationId),
    isSecure: true,
  });
}

async function readAllNotification(companyId: number): Promise<unknown> {
  return requestMaker({
    type: "GET",
    route: endpoints.READ_ALL_NOTIFICATION(companyId),
    isSecure: true,
  });
}

async function getNotificationByUser(
  email: string
): Promise<Record<string, IGetNotification>> {
  return requestMaker({
    type: "GET",
    route: endpoints.GET_NOTIFICATION_BY_USER(email),
    isSecure: true,
  });
}

async function updateCompany(
  data: IBusinessDetails,
  id: number
): Promise<INetworkResponse<ICompany>> {
  return requestMaker({
    type: "POST",
    route: endpoints.UPDATE_COMPANY(id),
    isSecure: true,
    data,
  });
}

async function addDirector(
  data: IDirectorData,
  id: number
): Promise<INetworkResponse<IAddDirectorResponse>> {
  return requestMaker({
    type: "POST",
    route: endpoints.ADD_DIRECTOR(id),
    isSecure: true,
    data,
  });
}

async function getDirectorByCompanyId(
  id: number
): Promise<INetworkResponse<IGetDirector>> {
  return requestMaker({
    type: "GET",
    route: endpoints.ADD_DIRECTOR(id),
    isSecure: true,
  });
}
async function editDirector(
  data: IDirectorData,
  id: number
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "PUT",
    route: endpoints.ADD_DIRECTOR(id),
    isSecure: true,
    data,
  });
}

async function uploadPayment(
  data: IUpload
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.UPLOAD_PAYMENT,
    isSecure: true,
    data,
  });
}

async function viewUploads(
  queryParams: IQuery
): Promise<INetworkResponse<IGetUploads>> {
  return requestMaker({
    type: "GET",
    route: endpoints.VIEW_UPLOADS,
    isSecure: true,
    queryParams,
  });
}

async function viewUploadComments(
  id: number
): Promise<Record<string, IUploadCommentsResponseData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.VIEW_UPLOAD_COMMENTS(id),
    isSecure: true,
  });
}

async function viewUpload(
  query: IQuery,
  batchId: string
): Promise<INetworkResponse<IGetUpload>> {
  return requestMaker({
    type: "GET",
    route: endpoints.VIEW_UPLOAD(batchId),
    isSecure: true,
    queryParams: query,
  });
}

async function getUsers({
  page,
  size,
}: {
  page: number;
  size: number;
}): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "GET",
    route: endpoints.GET_USERS(page, size),
    isSecure: true,
  });
}

async function addTeamMember(
  data: IAddTeam
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.TEAM,
    isSecure: true,
    data,
  });
}

async function editTeamMember(
  data: Partial<IAddTeam>,
  id: number
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "PUT",
    route: endpoints.TEAM + `/${id}`,
    isSecure: true,
    data,
  });
}

async function getTeamMembers(
  query: IQuery
): Promise<INetworkResponse<IGetTeamData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.TEAM,
    isSecure: true,
    queryParams: query,
  });
}

async function getRoles(): Promise<INetworkResponse<Array<IRoleData>>> {
  return requestMaker({
    type: "GET",
    route: endpoints.GET_ROLES,
    isSecure: true,
  });
}

async function updateTeamSettings(
  data: ITeamSettings
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.TEAM_SETTING,
    isSecure: true,
    data,
  });
}

async function updateUser(
  data: IUpdateUser
): Promise<INetworkResponse<ILoginResponseData["user"]>> {
  return requestMaker({
    type: "PUT",
    route: endpoints.GET_USER,
    isSecure: true,
    data,
  });
}

async function updateProfilePicture(
  base64Image: string
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "PUT",
    route: endpoints.UPDATE_PROFILE_PICTURE,
    isSecure: true,
    data: { base64Image },
  });
}

async function getNotificationSettings(): Promise<
  INetworkResponse<IGetNotificationSettingsData>
> {
  return requestMaker({
    type: "GET",
    route: endpoints.NOTIFICATION_SETTINGS,
    isSecure: true,
  });
}

async function updateNotificationSettings(
  data: IUpdateNotificationData
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "PUT",
    route: endpoints.NOTIFICATION_SETTINGS,
    isSecure: true,
    data,
  });
}

async function getCompanyDocument(
  id: number
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "GET",
    route: endpoints.GET_COMPANY_DOCUMENT(id),
    isSecure: true,
  });
}

async function resendUpload(
  data: IResendUpload
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.RESEND_UPLOAD,
    isSecure: true,
    data,
  });
}

async function approveRejectUpload(
  batchId: string,
  data: IApproveRejectPayload
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.APPROVE_REJECT_UPLOAD(batchId),
    isSecure: true,
    data,
  });
}

async function activateWallet(
  data: IActivateWalletData
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.ACTIVATE_WALLET,
    isSecure: true,
    data,
  });
}

async function getWalletDashboardDetails(): Promise<
  INetworkResponse<IWalletDashboardResponseData>
> {
  return requestMaker({
    type: "GET",
    route: endpoints.WALLET_DASHBOARD_DETAILS,
    isSecure: true,
  });
}

async function getWalletTransactionHistory(
  queryParams: IQuery
): Promise<INetworkResponse<IWalletTransactionData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.WALLET_TRANSACTION_HISTORY,
    isSecure: true,
    queryParams,
  });
}

async function getWalletTransactionSummary(
  queryParams: IQuery
): Promise<INetworkResponse<IWalletReportResponseData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.WALLET_TRANSACTION_SUMMARY,
    isSecure: true,
    queryParams,
  });
}

async function getWalletDetails(): Promise<
  INetworkResponse<IWalletDetailsData>
> {
  return requestMaker({
    type: "GET",
    route: endpoints.WALLET_DETAILS,
    isSecure: true,
  });
}

async function yearlyTransactionCount(): Promise<INetworkResponse<ICountData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.TRANSACTION_COUNT_YEARLY,
    isSecure: true,
  });
}

async function monthlyTransactionCount(): Promise<
  INetworkResponse<ICountData>
> {
  return requestMaker({
    type: "GET",
    route: endpoints.TRANSACTION_COUNT_MONTHLY,
    isSecure: true,
  });
}

async function weeklyTransactionCount(): Promise<INetworkResponse<ICountData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.TRANSACTION_COUNT_WEEKLY,
    isSecure: true,
  });
}

async function transactionCountByDate(
  queryParams: IQuery
): Promise<INetworkResponse<ICountData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.TRANSACTION_COUNT,
    isSecure: true,
    queryParams,
  });
}

async function enable2fa(data: IEnable2fa): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.GET_USER + "/enable-2fa",
    isSecure: true,
    data,
  });
}

async function activate2fa(
  data: IEnable2fa,
  token: string
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "POST",
    route: endpoints.GET_USER + `/activate-2fa/${token}`,
    isSecure: true,
    data,
  });
}

async function getTransactionHistory(
  queryParams?: IQuery
): Promise<INetworkResponse<ITransactionResponseData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.TRANSACTION_HISTORY,
    isSecure: true,
    queryParams,
  });
}

async function getTransactionHistoryDetails(
  id: string,
  queryParams?: IQuery
): Promise<INetworkResponse<ITransactionDetailsResponseData>> {
  return requestMaker({
    type: "GET",
    route: endpoints.TRANSACTION_HISTORY_DETAILS(id),
    isSecure: true,
    queryParams,
  });
}

async function downloadPdfReport(
  queryParams?: IQuery
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "GET",
    route: endpoints.DOWNLOAD_PDF_REPORT,
    isSecure: true,
    queryParams,
  });
}

async function downloadExcelReport(
  queryParams?: IQuery
): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "GET",
    route: endpoints.DOWNLOAD_EXCEL_REPORT,
    isSecure: true,
    queryParams,
  });
}

async function getDashboardCount(): Promise<
  INetworkResponse<IDashboardCountResponseData>
> {
  return requestMaker({
    type: "GET",
    route: endpoints.DASHBOARD_COUNT,
    isSecure: true,
  });
}

async function disableUser(id: number): Promise<INetworkResponse<unknown>> {
  return requestMaker({
    type: "PUT",
    route: endpoints.DISABLE_USER(id),
    isSecure: true,
  });
}

const api = {
  register,
  registerAuth,
  login,
  twoFactorLogin,
  resendOtp,
  forgotPassword,
  forgotPasswordActivation,
  changePassword,
  getUser,
  logout,
  getNotification,
  updateCompany,
  addDirector,
  uploadPayment,
  viewUploads,
  getUsers,
  editDirector,
  getDirectorByCompanyId,
  addTeamMember,
  getTeamMembers,
  getRoles,
  updateTeamSettings,
  updateUser,
  updateProfilePicture,
  getNotificationSettings,
  updateNotificationSettings,
  getCompanyDocument,
  resendUpload,
  viewUpload,
  approveRejectUpload,
  getNotificationByUser,
  readNotification,
  activateWallet,
  getWalletDashboardDetails,
  getWalletTransactionHistory,
  getWalletTransactionSummary,
  getWalletDetails,
  yearlyTransactionCount,
  monthlyTransactionCount,
  weeklyTransactionCount,
  viewUploadComments,
  readAllNotification,
  enable2fa,
  activate2fa,
  editTeamMember,
  getTransactionHistory,
  transactionCountByDate,
  downloadPdfReport,
  downloadExcelReport,
  getDashboardCount,
  getTransactionHistoryDetails,
  disableUser,
};

export default api;
