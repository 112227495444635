import React, { CSSProperties, ChangeEvent, FC } from "react";
import Text from "../Text";

interface IProps {
  label: JSX.Element | string;
  checked?: boolean;
  name: string;
  labelStyle?: CSSProperties;
  error?: string;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
}

const Checkbox: FC<IProps> = ({
  checked = false,
  disabled = false,
  ...props
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) props.onChange(e.target.checked);
  };
  return (
    <div>
      <div className="flex items-center">
        <input
          type="checkbox"
          id="myCheckbox"
          defaultChecked={checked}
          className="mr-2 h-[16px] w-[16px] rounded border border-gray_300 bg-base_White focus:ring-0"
          onChange={handleChange}
          disabled={disabled}
        />
        <label
          style={props.labelStyle ? props.labelStyle : {}}
          htmlFor="myCheckbox"
        >
          {props.label}
        </label>
      </div>
      {props.error && (
        <div className="mt-[5px]">
          <Text text={props.error} size={14} color="text-error_500" />
        </div>
      )}
    </div>
  );
};

export default Checkbox;
