import React, { FC, useEffect, useRef, useState } from "react";
import Text from "../Text";
import { Icon } from "../Icons";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { capitalize, removeUnderscore } from "../../utils/utilMethods";

interface IProps {
  label: string;
  id?: string;
  name: string;
  placeholder: string;
  items: Array<{ label: string; value: string }>;
  selectedValue?: string;
  onChange?: (label: string, value: string) => void;
  error?: string;
}
const DropdownInput: FC<IProps> = ({
  label,
  placeholder,
  items,
  selectedValue,
  ...props
}) => {
  const ref = useRef(null);

  useOnClickOutside(ref, () => setOpen(false));
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedValue) {
      const selectedItem = items.find((item) => item.value === selectedValue);
      if (selectedItem) {
        setValue(selectedItem.label);
      } else {
        setValue("");
      }
    }
  }, [selectedValue]);

  function toggle() {
    setOpen((prev) => !prev);
  }
  function handleChange(valueLabel: string, value: string) {
    setValue(valueLabel);
    if (props.onChange) props.onChange(valueLabel, value);
  }
  return (
    <div>
      <label className="text-sm leading-[20px] text-gray_700 font-medium font-Euclid">
        {label}
      </label>

      <div
        onClick={toggle}
        ref={ref}
        className={`h-40 mt-1 shadow-xs bg-white rounded-lg px-2.5 border ${props.error ? "border-error_300" : "border-gray_200"} focus:shadow-inputFocus text-gray_500 w-[100%] flex justify-between items-center cursor-pointer relative`}
      >
        <Text
          color="text-gray_500"
          text={
            capitalize(removeUnderscore(value)) ||
            capitalize(removeUnderscore(selectedValue ?? "")) ||
            capitalize(removeUnderscore(placeholder))
          }
          classNames="max-w-[fit-content] overflow-hidden text-ellipsis whitespace-nowrap"
        />
        <Icon name="caret_down" rotate={open ? 180 : 0} />

        {open && (
          <div
            className="absolute top-[40px] w-[100%] left-0 rounded-lg border border-gray_100 
        bg-base_White shadow-lg mb-[50px]"
            style={{ zIndex: 1000 }}
          >
            {items.length > 0 &&
              items.map((item, index) => (
                <div
                  key={item.value + index}
                  className="flex justify-between py-10 px-14 items-center hover:bg-gray_50"
                  onClick={() => handleChange(item.label, item.value)}
                >
                  <Text
                    text={capitalize(removeUnderscore(item.label))}
                    color="text-gray_900"
                    size={16}
                  />

                  {value === item.label && <Icon name="check" />}
                </div>
              ))}
          </div>
        )}
      </div>
      {props.error && (
        <div className="mt-[5px]">
          <Text text={props.error} size={14} color="text-error_500" />
        </div>
      )}
    </div>
  );
};

export default DropdownInput;
