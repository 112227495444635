import { configureStore } from "@reduxjs/toolkit";
import authReducer, { authSlice } from "./features/authSlice";
import globalReducer, { globalSlice } from "./features/globalSlice";
import paymentReducer, { paymentSlice } from "./features/paymentSlice";

const store = configureStore({
  reducer: {
    [authSlice.name]: authReducer,
    [globalSlice.name]: globalReducer,
    [paymentSlice.name]: paymentReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
