import React, { FC } from "react";
import Text from "../Text";

interface IProps {
  action?: () => void;
  info: string;
  actionText?: string;
  disableAction?: boolean;
}
const InfoAndAction: FC<IProps> = ({ ...props }) => {
  return (
    <div className="font-Euclid flex gap-[4px] font-gray_500 ">
      <div>
        <Text text={props.info} size={14} color="text-gray_500" />
      </div>
      {props.actionText && (
        <div
          className="cursor-pointer"
          onClick={
            props.action && !props.disableAction ? props.action : () => null
          }
        >
          <Text
            text={props.actionText}
            size={14}
            color={
              props.disableAction ? "text-gray_300" : "text-primary_Blue_700"
            }
            classNames="font-medium"
          />
        </div>
      )}
    </div>
  );
};

export default InfoAndAction;
