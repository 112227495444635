import React, { Children, FC, ReactNode } from "react";

interface IDrawerprops {
  toggle: () => void;
  show: boolean;
  title: string;
  children: ReactNode;
  showFooterButton?: boolean;
  footerButton?: JSX.Element | null;
}
const Drawer: FC<IDrawerprops> = ({
  toggle,
  show,
  title,
  children,
  showFooterButton,
  footerButton,
}) => {
  return (
    <>
      <div
        onClick={toggle}
        className={`${show ? "bg-black/20 fixed inset-0 z-30 bg-r" : ""}`}
      ></div>
      <div
        id="drawer-right-example"
        className={`fixed font-Euclid top-0 right-0 z-40 h-screen overflow-y-auto transition-transform ${show ? "transform-none" : "translate-x-full"} bg-white w-80 lg:w-[500px] flex flex-col`}
        tabIndex={-1}
        aria-labelledby="drawer-right-label"
      >
        <header className="flex items-center py-[28px] px-[27px] border-b border-b-gray_200">
          {/* <!--Modal title--> */}
          <h3 className="text-lg font-medium text-gray_900">{title}</h3>
          {/* <!--Close button--> */}
          <button
            type="button"
            className="text-[#343B46] bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6  ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
            data-modal-hide="default-modal"
            onClick={toggle}
          >
            <svg
              className="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span className="sr-only">Close drawer</span>
          </button>
        </header>
        {children}
        {showFooterButton && footerButton && (
          <div className="mt-auto flex items-center min-h-[100px] px-[27px] border-t border-t-gray_200">
            {footerButton}
          </div>
        )}
      </div>
    </>
  );
};

export default Drawer;
