import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Icon, IconProps } from "../Icons";
import Button from "../Button";
import Text from "../Text";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./styles.css";
import { MAX_FILE_SIZE, MAX_MULTIPLE_FILE_SIZE } from "../../utils/constants";
import { Document, Page } from "react-pdf";
import { getFileIconName, getFileSize } from "../../utils/utilMethods";
import useToast from "../../hooks/useToast";

export const UploadedFile: FC<{
  name: string;
  size: string;
  fileType: "csv" | "pdf" | "jpg";
  onClickAction: () => void;
}> = ({ fileType, name, size, onClickAction }) => {
  return (
    <div className="h-[52px] p-8 flex flex-row justify-between items-center bg-gray_50 rounded-lg">
      <div className="flex gap-2 items-center">
        <Icon name={fileType} />

        <div>
          <Text
            text={name}
            size={12}
            color="text-gray_700"
            classNames="font-medium"
          />

          <Text text={size} color="text-gray_500" size={12} />
        </div>
      </div>

      <div className="cursor-pointer" onClick={onClickAction}>
        <Icon name="download_action" />
      </div>
    </div>
  );
};

const UploadComponent: FC<{
  title?: string;
  info?: JSX.Element;
  accept?: string;
  description?: string;
  uploadButtonText?: string;
  showDescription?: boolean;
  disabled?: boolean;
  multiple?: boolean;
  onSingleFileChange?: (file: FileList["0"]) => void;
  showUploadStatus?: boolean;
  onRemove?: () => void;
  selectedFile?: File;
}> = ({
  title = "",
  info,
  accept = ".pdf,.jpg,.png",
  showDescription,
  description = "PDF or JPG (max. file size of 2mb)",
  uploadButtonText = "Click to upload file",
  disabled,
  multiple = false,
  onSingleFileChange,
  showUploadStatus = true,
  onRemove,
  ...props
}) => {
  const toast = useToast();

  const [file, setFile] = useState<FileList["0"]>();
  const [multipleFiles, setMultipleFiles] = useState<File[]>([]);
  const [filePreviews, setFilePreviews] = useState<Array<string | null>>([]);
  const [error, setError] = useState("");
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (props.selectedFile) {
      setFile(props.selectedFile);
    } else {
      setFile(props.selectedFile);
    }
  }, [props.selectedFile]);

  useEffect(() => {
    let timer: NodeJS.Timer;
    if (file) {
      setProgress((prev) => 0);
      timer = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(timer);
            return 100;
          }
          return prev + 10;
        });
      }, 100);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [file]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (!e.target.files) return;
      setError("");

      if (multiple) {
        const selectedFiles = [...multipleFiles, ...Array.from(e.target.files)];
        if (selectedFiles) {
          setMultipleFiles((prev) => [...selectedFiles]);
          const filePreviewsArray: (string | null)[] = Array.from(
            selectedFiles
          ).map(() => null);
          setFilePreviews((prev) => [...prev, ...filePreviewsArray]);

          Array.from(selectedFiles).forEach((file, index) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              if (typeof reader.result === "string") {
                setFilePreviews((prevPreviews) => {
                  const newPreviews: Array<string | null> = [...prevPreviews];
                  newPreviews[index] = reader.result as string;
                  return newPreviews;
                });
              }
            };
            // if (file.type === "application/pdf") {
            //   reader.readAsArrayBuffer(file);
            // } else {
            reader.readAsDataURL(file);
            // }
          });
        }

        return;
      }
      const selectedFile = e.target.files[0];
      if (selectedFile.size > MAX_FILE_SIZE) {
        setError("File should not be more than 2MB");
        setFile(selectedFile);
        setProgress(0);
        return;
      }
      setFile(selectedFile);
      setProgress(0);
      if (onSingleFileChange) onSingleFileChange(selectedFile);
      if (fileInputRef.current) fileInputRef.current.value = "";
    } catch (error) {
      toast.error("An error occured");
    }
  };

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const remove = () => {
    setFile(undefined);
    if (onRemove) onRemove();
  };
  const removeFromMultiple = (index: number) => {
    setMultipleFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    setFilePreviews((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  return (
    <div>
      {title && (
        <div className="mb-2">
          <Text
            text={title}
            size={14}
            color="text-gray_700"
            classNames="font-medium"
          />
        </div>
      )}
      <div
        className={`mb-[16px] border border-dashed border-gray_200 rounded-lg min-h-[138px] py-[16px] px-[24px] flex flex-col  items-center gap-1 ${disabled ? "bg-gray_50" : "bg-base_White"} `}
      >
        <div>
          <Icon name="upload" />
        </div>
        <div>
          <Button
            variant="link"
            label={uploadButtonText}
            onClick={handleFileUpload}
            disabled={disabled}
          />
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            accept={accept}
            multiple={multiple === true}
          />
        </div>
        {showDescription && (
          <div>
            <Text text={description} size={12} color="text-gray_500" />
          </div>
        )}
      </div>
      <div>
        <div className="flex flex-row gap-3 flex-wrap">
          {filePreviews
            .filter((preview) => preview)
            .map((preview, index) => (
              <div key={index} className="relative">
                <div
                  className="absolute -right-3 -top-1 bottom-0 z-30 overflow-visible cursor-pointer"
                  onClick={() => removeFromMultiple(index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <rect width={24} height={24} rx={12} fill="white" />
                    <path
                      d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 10.5858L14.8284 7.75736L16.2426 9.17157L13.4142 12L16.2426 14.8284L14.8284 16.2426L12 13.4142L9.17157 16.2426L7.75736 14.8284L10.5858 12L7.75736 9.17157L9.17157 7.75736L12 10.5858Z"
                      fill="#475467"
                    />
                  </svg>
                </div>
                <div className="relative rounded-lg w-[75px] h-[71px] overflow-hidden  border border-gray_200 ">
                  {/* overlay */}
                  <div className="absolute rounded-lg w-[75px] h-[71px] bg-opacity-20 bg-black" />

                  {preview && (
                    <>
                      {preview?.startsWith("data:image") ? (
                        <img
                          src={preview}
                          alt={`File Preview ${index}`}
                          width={75}
                          height={71}
                          style={{ maxWidth: "100px", marginRight: "10px" }}
                        />
                      ) : preview.startsWith("data:video") ? (
                        <video
                          controls
                          style={{
                            width: 75,
                            height: 71,
                            marginRight: "10px",
                          }}
                        >
                          <source src={preview} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : preview.startsWith("data:application/pdf") ? (
                        <div style={{ maxWidth: "100px", marginRight: "10px" }}>
                          <Document file={{ url: preview }}>
                            <Page pageNumber={1} width={100} />
                          </Document>
                        </div>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
      {info && <div>{info}</div>}
      {file && !multiple && showUploadStatus && (
        <div className="min-h-[52px] p-8 flex flex-row justify-between items-center bg-gray_50 rounded-lg">
          <div className="flex gap-2 items-center">
            <Icon name={getFileIconName(file)} />

            <div>
              <Text
                text={file.name}
                size={12}
                color="text-gray_700"
                classNames="font-medium"
              />
              {!error && (
                <Text
                  text={getFileSize(file)}
                  color="text-gray_500"
                  size={12}
                />
              )}
              {error && <Text text={error} color="text-error_500" size={12} />}
            </div>
          </div>
          {progress !== 100 && !error && (
            <div style={{ width: 28, height: 28, position: "relative" }}>
              <CircularProgressbar
                value={progress}
                styles={buildStyles({
                  pathColor: "#3B82F6",
                  trailColor: "#E2E8F0",
                })}
              />
              <div className="absolute top-[4px] right-[9.5px] text-[12px] text-gray_500">
                {" "}
                &#x2715;
              </div>
            </div>
          )}
          {progress === 100 && !error && (
            <div className="cursor-pointer" onClick={remove}>
              <Icon name="delete" />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadComponent;
