import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Modal from "../../../../components/Modal";
import Button from "../../../../components/Button";
import { Icon } from "../../../../components/Icons";
import TextInput from "../../../../components/TextInput";
import Text from "../../../../components/Text";
import useToast from "../../../../hooks/useToast";
import { useValidation } from "../../../../hooks/useValidation";
import { setPasswordValidation } from "../../../../utils/validations";
import { handleError } from "../../../../utils/error";
import api from "../../../../network/api";
import { useAppSelector } from "../../../../hooks/redux";
import { selectUser } from "../../../../redux/features/authSlice";
import { IChangePassword } from "../../../../interface/auth";

interface IChangePasswordModal {
  toggle: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
}
const ChangePasswordModal: FC<IChangePasswordModal> = ({
  toggle,
  setShow,
  show,
}) => {
  const toast = useToast();
  const user = useAppSelector(selectUser);
  const { data, addData, deleteData, validated, validationResult } =
    useValidation(setPasswordValidation);

  const [loading, setLoading] = useState(false);

  const handleChange = (name: string, value: string) => {
    addData(name, value);
  };
  const changePassword = async () => {
    setLoading(true);
    try {
      if (!user) return;
      const response = await api.changePassword(
        user?.email,
        data as IChangePassword
      );

      toast.success(response.message);
      deleteData();
      toggle();
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };
  const footerButtons = () => {
    return (
      <div className="flex flex-row items-center justify-center gap-3">
        <div>
          <Button label="Cancel" classNames="lg:w-[210px]" onClick={toggle} />
        </div>
        <div>
          <Button
            label="Change password"
            classNames="lg:w-[210px]"
            variant="primary"
            disabled={!validated}
            loading={loading}
            onClick={changePassword}
          />
        </div>
      </div>
    );
  };
  return (
    <Modal
      title="Change password"
      toggle={toggle}
      setShow={setShow}
      show={show}
      footerElement={footerButtons()}
      footerClasses="flex items-center justify-center py-[30px] px-[31px] border-t border-t-[#F3F2F2]"
    >
      <div className="flex flex-col gap-5">
        <div>
          <Icon name="create_password_icon" />
          <Text
            text="Enter a unique password to secure your account"
            size={14}
            color="text-gray_800"
          />
        </div>
        <TextInput
          label="Old Password"
          id="oldPassword"
          placeholder="Enter old password"
          name="password"
          type="password"
          onInputChange={handleChange}
          error={validationResult.password}
        />
        <TextInput
          label="New Password"
          id="newPassword"
          placeholder="Enter new password"
          name="newPassword"
          type="password"
          onInputChange={handleChange}
          error={validationResult.newPassword}
        />
        <TextInput
          label="Confirm Password"
          id="confirmPassword"
          placeholder="Confirm new password"
          name="passwordConfirmation"
          type="password"
          onInputChange={handleChange}
          error={validationResult.passwordConfirmation}
        />
      </div>
    </Modal>
  );
};
export default ChangePasswordModal;
