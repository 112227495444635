import React, {
  ChangeEvent,
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  useEffect,
  useState,
} from "react";
import Button from "../Button";
import Text from "../Text";
import { Icon } from "../Icons";

interface InputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement & HTMLTextAreaElement>,
    HTMLInputElement
  > {
  label?: string;
  /**
   * text | number| email
   *
   */
  type?: "text" | "number" | "email" | "password" | "textArea";
  id: string;
  name: string;
  prepend?: true;
  prependItem?: JSX.Element;
  info?: string;
  error?: string;
  rows?: number;
  onInputChange?: (name: string, value: string) => void;
}
const TextInput: FC<InputProps> = ({ label, type = "text", id, ...props }) => {
  const Input = document.getElementById(id);
  const [showPassword, setShowPassword] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");

  useEffect(() => {
    if (Input && type === "number") {
      Input.addEventListener("wheel", preventInputScroll);
    }

    return () => {
      if (Input && type === "number")
        Input.removeEventListener("wheel", preventInputScroll);
    };
  }, []);

  useEffect(() => {
    // if (props.defaultValue) {
    setInputValue(props.defaultValue?.toString() ?? "");
    // }
  }, [props.defaultValue]);

  function handlePasswordType() {
    if (type === "password" && showPassword) {
      return "text";
    }
    return "password";
  }

  function preventInputScroll(e: MouseEvent) {
    e.preventDefault();
  }

  function togglePassword() {
    setShowPassword((prev) => !prev);
  }

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
  ): void => {
    if (props.onInputChange) props.onInputChange(props.name, e.target.value);
    setInputValue(e.target.value);
  };

  if (type === "password") {
    return (
      <div>
        {label && (
          <label
            className="text-sm leading-[20px] text-gray_700 font-medium font-Euclid"
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div className="flex">
          <input
            className="h-40 mt-1 shadow-xs disabled:bg-gray_50 bg-white rounded-l-lg px-2.5 border border-r-0 border-gray_200 focus:outline-0 focus:ring-0 focus:border focus:border-primary_Blue_300 focus:shadow-inputFocus text-gray_500 placeholder:font-normal placeholder:text-gray_300 w-[100%]"
            id={id}
            name={props.name}
            type={handlePasswordType()}
            onChange={handleChange}
            placeholder={props.placeholder ?? "Input text.."}
            // defaultValue={props.defaultValue ?? ""}
            value={inputValue}
            disabled={props.disabled}
          />
          <button
            type="button"
            onClick={togglePassword}
            className="px-4 py-2 h-40 mt-1 w-[85px] bg-base_white text-gray_700  border border-gray_20 rounded-r-md"
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
        {props.error && (
          <div className="mt-[5px]">
            <Text text={props.error} size={14} color="text-error_500" />
          </div>
        )}
      </div>
    );
  }

  if (type === "textArea") {
    return (
      <div className="relative">
        {label && (
          <label
            className="text-sm leading-[20px] text-gray_700 font-medium font-Euclid"
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {props.prepend && props.prependItem && (
          <div className="absolute  left-2.5  top-[40px]">
            {props.prependItem}
          </div>
        )}
        <textarea
          className={`mt-1 resize-none shadow-xs disabled:bg-gray_50 bg-white rounded-lg px-2.5 border border-gray_200 focus:outline-0 focus:ring-0 focus:border focus:border-primary_Blue_300 focus:shadow-inputFocus text-gray_500 placeholder:font-normal placeholder:text-gray_300 w-[100%] ${props.prepend ? "pl-[50px]" : ""}`}
          id={id}
          // defaultValue={props.defaultValue ?? ""}
          value={inputValue}
          name={props.name}
          onChange={handleChange}
          rows={props.rows ?? 3}
          placeholder={props.placeholder ?? "Input text.."}
          disabled={props.disabled}
        />
        {props.info && (
          <div className="mt-[5px]">
            <Text text={props.info} size={14} color="text-gray_500" />
          </div>
        )}
        {props.error && (
          <div className="mt-[5px]">
            <Text text={props.error} size={14} color="text-error_500" />
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="relative">
      {label && (
        <label
          className="text-sm leading-[20px] text-gray_700 font-medium font-Euclid"
          htmlFor={id}
        >
          {label}
        </label>
      )}
      {props.prepend && props.prependItem && (
        <div className="absolute  left-2.5  top-[40px]">
          {props.prependItem}
        </div>
      )}
      {props.error && (
        <div className="absolute  right-2.5  top-[40px]">
          <Icon name="input_error" />
        </div>
      )}
      <input
        className={`h-40 mt-1 shadow-xs bg-white  disabled:bg-gray_50 rounded-lg px-2.5 border ${props.error ? "border-error_300" : "border-gray_200"} focus:outline-0 focus:ring-0 focus:border focus:border-primary_Blue_300 focus:shadow-inputFocus text-gray_500 placeholder:font-normal placeholder:text-gray_300 w-[100%] ${props.prepend ? "pl-[50px]" : ""}`}
        id={id}
        // defaultValue={props.defaultValue ?? ""}
        value={inputValue}
        name={props.name}
        type={type}
        onChange={handleChange}
        placeholder={props.placeholder ?? "Input text.."}
        disabled={props.disabled}
      />
      {props.info && (
        <div className="mt-[5px]">
          <Text text={props.info} size={14} color="text-gray_500" />
        </div>
      )}
      {props.error && (
        <div className="mt-[5px]">
          <Text text={props.error} size={14} color="text-error_500" />
        </div>
      )}
    </div>
  );
};

export default TextInput;
