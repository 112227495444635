import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Title from "../../components/Title";
import InfoAndAction from "../../components/infoAndAction";
import {
  DASHBOARD,
  FORGOTPASSWORD,
  LOGIN,
  REGISTER,
  VERIFICATION,
} from "../../routes/navigations";
import { IError } from "../../interface/error";
import useToast from "../../hooks/useToast";
import api from "../../network/api";
import { useValidation } from "../../hooks/useValidation";
import { loginValidation } from "../../utils/validations";
import { ILogin } from "../../interface/auth";
import { useAppDispatch } from "../../hooks/redux";
import {
  authenticate,
  clientRoles,
  setRole,
  setUser,
} from "../../redux/features/authSlice";
import { removeUnderscore } from "../../utils/utilMethods";

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { addData, validated, validationResult, data } =
    useValidation(loginValidation);
  const [loading, setLoading] = useState(false);

  function handleChange(name: string, value: string) {
    addData(name, value);
  }

  async function verify(e?: FormEvent) {
    if (e) {
      e.preventDefault();
    }
    setLoading(true);
    try {
      const response = await api.login(data as ILogin);
      if (response.message === "Activation Token sent") {
        navigate(VERIFICATION, {
          state: {
            from: LOGIN,
            email: data.email,
            password: data.password,
            authMode: response?.data?.authMode,
          },
        });
        return;
      }
      const token = response.data?.token;
      const user = response.data?.user;

      if (user) {
        if (user.role.name === "PORTAL_ADMIN") throw new Error("Access denied");
        dispatch(setUser(user));
        dispatch(
          setRole(
            clientRoles[
              removeUnderscore(
                user.role?.name?.toLowerCase()
              ) as keyof typeof clientRoles
            ]
          )
        );
      }
      if (token) dispatch(authenticate(token));

      // navigate(VERIFICATION);
      navigate(DASHBOARD);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "Unable to login at the moment");
    }

    setLoading(false);
  }
  return (
    <div className="w-[100%] flex justify-center my-12">
      <form
        className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6"
        onSubmit={verify}
      >
        <Title
          title="Log in to your account"
          icon="logo"
          description="Welcome back! Please enter your details."
        />
        <div className="w-[100%]">
          <TextInput
            label="Email"
            id="loginEmail"
            placeholder="Enter your email"
            name="email"
            type="email"
            onInputChange={handleChange}
            error={validationResult.email}
          />
        </div>
        <div className="w-[100%]">
          <TextInput
            label="Password"
            type="password"
            id="loginPassword"
            placeholder="*******"
            name="password"
            onInputChange={handleChange}
            error={validationResult.password}
          />
        </div>
        <div className="w-[100%]">
          <Button
            variant="link"
            label="Forgot password"
            onClick={() => navigate(FORGOTPASSWORD)}
          />
        </div>
        <Button
          variant="primary_lg"
          label="Login"
          classNames="w-[100%]"
          loading={loading}
          disabled={!validated}
          onClick={verify}
          type="submit"
        />

        <InfoAndAction
          info="Don’t have an account?"
          actionText="Sign up"
          action={() => navigate(REGISTER)}
        />
      </form>
    </div>
  );
};

export default Login;
