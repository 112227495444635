import { lazy } from "react";
import Dashboard from "../Screens/dashboard";
const Wallet = lazy(() => import("../Screens/wallet"));
const Transactions = lazy(() => import("../Screens/transactions"));
const Team = lazy(() => import("../Screens/team"));
const Uploads = lazy(() => import("../Screens/uploads"));
const Reports = lazy(() => import("../Screens/reports"));
const Support = lazy(() => import("../Screens/support"));
const Settings = lazy(() => import("../Screens/settings"));
const TransactionDetails = lazy(() => import("../Screens/transactionDetails"));

import {
  DASHBOARD,
  REPORTS,
  HELP_AND_SUPPORT,
  TEAM,
  TRANSACTIONS,
  UPLOADS,
  WALLET,
  SETTINGS,
} from "./navigations";

const privateRoutes = [
  { path: DASHBOARD, element: Dashboard },
  { path: WALLET, element: Wallet },
  { path: TRANSACTIONS, element: Transactions },
  { path: `${TRANSACTIONS}/:id`, element: TransactionDetails },
  { path: TEAM, element: Team },
  { path: UPLOADS, element: Uploads },
  { path: REPORTS, element: Reports },
  // { path: HELP_AND_SUPPORT, element: Support },
  { path: SETTINGS, element: Settings },
];

export default privateRoutes;
