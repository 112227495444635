import React, { CSSProperties, FC } from "react";
import Text from "../Text";
import Button from "../Button";
import { IconProps } from "../Icons";
import AccessControl from "../AccessControl";

interface IPageTitle {
  title: string;
  description?: string;
  buttonOneLable?: string;
  buttonTwoLabel?: string;
  buttonOneIcon?: IconProps["name"];
  buttonOneIconPosition?: "left" | "right";
  buttonTwoIcon?: IconProps["name"];
  buttonTwoIconPosition?: "left" | "right";
  onClickButtonOne?: () => void;
  onClickButtonTwo?: () => void;
  style?: CSSProperties;
  buttonOnePermission?: string[];
  buttonTwoPermission?: string[];
}
const PageTitle: FC<IPageTitle> = ({
  title,
  description,
  buttonOneLable,
  buttonOneIcon,
  buttonOneIconPosition,
  buttonTwoLabel,
  buttonTwoIcon,
  buttonTwoIconPosition,
  onClickButtonOne,
  onClickButtonTwo,
  style,
  buttonOnePermission,
  buttonTwoPermission,
}) => {
  return (
    <div
      style={style ? { ...style } : {}}
      className="flex flex-col lg:flex-row gap-4  justify-between items-center border-b border-gray_200 pb-5"
    >
      <div className="flex flex-col gap-1">
        <Text
          text={title}
          size={30}
          color="text-gray_900"
          classNames="font-medium"
        />
        {description && (
          <Text text={description} size={16} color="text-gray_500" />
        )}
      </div>
      <div className="flex flex-col lg:flex-row gap-4">
        <AccessControl requiredPermission={buttonOnePermission ?? []}>
          {buttonOneLable && (
            <Button
              label={buttonOneLable}
              icon={buttonOneIcon}
              iconPosition={buttonOneIconPosition}
              onClick={onClickButtonOne}
            />
          )}
        </AccessControl>

        <AccessControl requiredPermission={buttonTwoPermission ?? []}>
          {buttonTwoLabel && (
            <Button
              variant="primary"
              label={buttonTwoLabel}
              icon={buttonTwoIcon}
              iconPosition={buttonTwoIconPosition}
              onClick={onClickButtonTwo}
            />
          )}
        </AccessControl>
      </div>
    </div>
  );
};

export default PageTitle;
