import React, { Dispatch, FC, ReactNode, SetStateAction } from "react";
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import Button from "../Button";

interface IProps {
  toggle: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  title: string;
  children: ReactNode;
  footerElement?: JSX.Element;
  footerClasses?: string;
}
const Modal: FC<IProps> = ({
  show,
  toggle,
  setShow,
  title,
  children,
  footerClasses,
  footerElement,
}) => {
  return (
    <TEModal show={show} setShow={setShow}>
      <TEModalDialog className="fixed top-0 left-0 w-full overflow-auto h-full bg-opacity-10 flex items-center justify-center bg-black z-50 shadow-[0px 12px 16px -4px rgba(16, 24, 40, 0.10), 0px 4px 6px -2px rgba(16, 24, 40, 0.05)] font-Euclid">
        <div className="relative p-4 w-full max-h-full max-w-[582px] ">
          <TEModalContent className="modal-radius">
            <TEModalHeader className="flex items-center py-[30px] px-[31px] border-b border-b-[#F3F2F2]">
              {/* <!--Modal title--> */}
              <h3 className="text-lg font-medium text-gray_900">{title}</h3>
              {/* <!--Close button--> */}
              <button
                type="button"
                className="text-[#343B46] bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6  ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="default-modal"
                onClick={toggle}
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </TEModalHeader>
            {/* <!--Modal body--> */}
            <TEModalBody className="py-[20px] px-[31px]">
              {children}
            </TEModalBody>
            <TEModalFooter className={footerClasses}>
              {footerElement}
            </TEModalFooter>
          </TEModalContent>
        </div>
      </TEModalDialog>
    </TEModal>
  );
};

export default Modal;
