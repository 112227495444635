import React, { FC, useEffect, useRef, useState } from "react";
import Button from "../Button";
import Text from "../Text";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import { IQuery } from "../../interface/network";
import { useQuery } from "../../hooks/useQuery";
import { useLocation, useNavigate } from "react-router-dom";
import { clearObjectValues } from "../../utils/utilMethods";

export type IFilters = Array<{
  title: string;
  key: string;
  filters: Array<{ label: string; value: string; id: string }>;
}>;
export interface IProps {
  filters?: IFilters;
  selected?: Record<string, string>;
  callBack?: (data?: IQuery) => any;
}
const Filter: FC<IProps> = ({ ...props }) => {
  const query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [val, setVal] = useState<Record<string, string>>({});
  const [newFilters, setNewFilter] = useState<IFilters>([]);

  const toggle = () => {
    setShow((prev) => !prev);
  };
  const ref = useRef(null);

  useOnClickOutside(ref, () => setShow(false));
  const filters = [
    {
      title: "Filter by status",
      key: "status",

      filters: [
        { label: "All", value: "all", id: "statusAll" },
        { label: "Pending", value: "pending", id: "statusPending" },
        { label: "Successful", value: "successful", id: "statusSuccessful" },
        { label: "Failed", value: "failed", id: "statusFailed" },
        { label: "Refunded", value: "refunded", id: "statusRefunded" },
      ],
    },
    {
      title: "Filter by transaction type",
      key: "transactionType",
      filters: [
        { label: "All", value: "transactionType:all", id: "typeAll" },
        { label: "Credit", value: "credit", id: "typeCredit" },
        { label: "Debit", value: "debit", id: "typeDebit" },
      ],
    },
  ];

  useEffect(() => {
    if (!newFilters.length) {
      if (props.filters && props.filters?.length > 0) {
        setNewFilter(props.filters);
      } else {
        setNewFilter(filters);
      }
    }
  }, [props.filters]);

  useEffect(() => {
    if (props.selected) {
      setVal(props.selected);
    }
  }, [props.selected]);

  const handleChecked = (key: string, value: string) => {
    setVal({ ...val, [key]: value });
  };

  const onCancel = () => {
    const newObj = clearObjectValues(val);

    setVal({});
    toggle();
    if (props.callBack) props.callBack({ ...newObj, page: 1 });
  };
  const handleSave = () => {
    // for (const key in val) {
    //   query.set(key, val[key]);
    // }
    // navigate({
    //   pathname: location.pathname,
    //   search: query.toString(),
    // });
    if (props.callBack) props.callBack({ ...val, page: 1 });
    toggle();
  };
  return (
    <div className="relative" ref={ref}>
      <Button label="Filters" icon="filter" onClick={toggle} />
      {show && (
        <div className="absolute z-30 bg-base_White lg:-right-[29px] lg:top-[60px] h-[567px] lg:w-[419px] border border-gray_100 bg-base_white shadow-xl rounded-lg flex flex-col">
          <div className="flex flex-row justify-between w-[100%] items-center py-[30px] px-[31px] border-b border-b-[#F3F2F2]">
            <h3 className="text-lg font-medium text-gray_900">Filters</h3>

            <button
              type="button"
              className="text-[#343B46] bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-6 h-6  ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="default-modal"
              onClick={toggle}
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close filter</span>
            </button>
          </div>

          <div className="py-[30px] px-[31px]">
            {newFilters.length > 0 &&
              newFilters.map((item, i) => (
                <div
                  className="border-b border-b-gray_200 last:border-0 pb-2 mb-2"
                  key={item.key}
                >
                  <Text text={item.title} color="text-gray_700" size={16} />

                  <div className="mt-2">
                    {item.filters.map((filter, ind) => (
                      <div className="flex items-center mb-2" key={filter.id}>
                        <input
                          id={filter.id}
                          type="radio"
                          name={filter.label}
                          checked={val && filter.value === val[item.key]}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-0"
                          onChange={() => handleChecked(item.key, filter.value)}
                        />
                        <label
                          htmlFor={filter.id}
                          className="ms-2 text-sm font-medium text-gray_700"
                        >
                          {filter.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
          </div>
          <div className="mt-auto flex flex-row justify-center items-center h-[100px] px-[27px] border-t border-t-gray_200">
            <div className="flex flex-row gap-2">
              <Button
                label="Cancel"
                classNames="min-w-[150px]"
                onClick={onCancel}
              />
              <Button
                label="Apply"
                variant="primary"
                disabled={!val}
                onClick={handleSave}
                classNames="min-w-[150px]"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
