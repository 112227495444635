import React, { FC } from "react";
import ReactPaginate from "react-paginate";
import "./pagination.css";
import Text from "../Text";
import { Icon } from "../Icons";

export interface IOnClickData {
  index: number | null;
  selected: number;
  nextSelectedPage: number | undefined;
  event: object;
  isPrevious: boolean;
  isNext: boolean;
  isBreak: boolean;
  isActive: boolean;
}
const Pagination: FC<{
  pageCount: number;
  onPageChange: (selectedItem: { selected: number }) => void;
  initialPage: number;
  //   handleClick: (data: IOnClickData) => void;
}> = ({ pageCount, onPageChange, initialPage }) => {
  return (
    <ReactPaginate
      previousLabel={
        <div className="flex gap-3">
          <Icon name="arrow_left" />
          <Text text="Previous" size={14} color="text-gray_500" />{" "}
        </div>
      }
      nextLabel={
        <div className="flex gap-3">
          <Text text="Next" size={14} color="text-gray_500" />{" "}
          <Icon name="arrow_right" color="#677990" />
        </div>
      }
      breakLabel={"..."}
      breakClassName={"break-me"}
      pageCount={pageCount}
      marginPagesDisplayed={4}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName={"pagination"}
      //   subContainerClassName={"pages pagination"}
      activeClassName={"active"}
      forcePage={initialPage ? initialPage - 1 : 1}
      previousClassName="previous"
      nextClassName="next"
      previousLinkClassName="previous-link"
      //   onClick={handleClick}
    />
  );
};

export default Pagination;
