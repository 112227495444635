import React, { ReactNode } from "react";
import { useAppSelector } from "../../hooks/redux";
import { selectRoleAndPermission } from "../../redux/features/authSlice";
// import NoAccess from '../NoAccess';

interface IAccessControl {
  requiredPermission: Array<string>;
  children: ReactNode;
  isPage?: boolean;
}

const AccessControl: React.FC<IAccessControl> = ({
  requiredPermission,
  children,
  isPage,
}) => {
  const roleAndPermission = useAppSelector(selectRoleAndPermission);

  const permitted = (): boolean => {
    let allow = false;
    if (requiredPermission.length === 0) {
      allow = true;
    }
    requiredPermission.forEach((permission) => {
      if (roleAndPermission?.permissions.includes(permission)) {
        allow = true;
      }
    });

    return allow;
  };

  const renderContent = () => {
    if (permitted()) {
      return children;
    }
    if (isPage) {
      return <div>No Access</div>;
    }
    return null;
  };

  return <>{renderContent()}</>;
};

export default AccessControl;
