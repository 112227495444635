import React from "react";
import { Icon } from "../../../Icons";
import Text from "../../../Text";

const CardSuccess = () => {
  return (
    <div className="flex flex-col items-center mt-6 gap-3 text-center">
      <Icon name="success" />
      <div className="flex flex-col">
        <Text text="Payment successful" size={18} color="text-gray_900" />
        <Text
          text="N200,000,000"
          size={36}
          classNames="font-semibold text-[32px]"
          color="text-gray_900"
        />
        <Text
          text="has been added to your wallet"
          size={18}
          color="text-gray_900"
        />
      </div>
    </div>
  );
};
export default CardSuccess;
