export const geturl = () => {
  if (window.location.origin === "https://swiftmonie.swiftlink.ng") {
    return "https://api.swiftlink.ng";
  }
  return "https://api-demo.swiftlink.ng";
};

const BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + "/swiftmonie-backend/api/v1"
  : `${geturl()}/swiftmonie-backend/api/v1`;

const NOTIFICATION_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + "/notification-service/api/v1"
  : `${geturl()}/notification-service/api/v1`;

const WALLET_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + "/wallet-service/api/v1"
  : `${geturl()}/wallet-service/api/v1`;

const PAYMENT_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL + "/payment-service/api/v1"
  : `${geturl()}/payment-service/api/v1`;

const endpoints = {
  // auth
  REGISTER: `${BASE_URL}/unsecure/company/register`,
  REGISTER_AUTH: `${BASE_URL}/unsecure/company/update-password`,
  LOGIN: `${BASE_URL}/auth`,
  TWO_FA_LOGIN: (email: string, token: string) =>
    `${BASE_URL}/auth/mode/${email}/${token}`,
  RESEND_OTP: (email: string) =>
    `${BASE_URL}/unsecure/company/resend-otp/${email}`,
  FORGOT_PASSWORD: (email: string) =>
    `${BASE_URL}/unsecure/user/forget-password/${email}`,
  FORGOT_PASSWORD_ACTIVATION: (token: string) =>
    `${BASE_URL}/unsecure/user/forget-password/${token}`,
  CHANGE_PASSWORD: (email: string) =>
    `${BASE_URL}/unsecure/user/change-password/${email}`,
  GET_ROLES: `${BASE_URL}/unsecure/role`,
  GET_USER: `${BASE_URL}/user`,
  LOGOUT: `${BASE_URL}/auth/logout`,
  GET_NOTIFICATION: (companyId: number) =>
    `${NOTIFICATION_BASE_URL}/in-app/${companyId}/message-type/IN_APP`,
  GET_NOTIFICATION_BY_USER: (userEmail: string) =>
    `${NOTIFICATION_BASE_URL}/user/${userEmail}`,
  READ_NOTIFICATION: (notificationId: number) =>
    `${NOTIFICATION_BASE_URL}/read/notification/${notificationId}`,
  READ_ALL_NOTIFICATION: (companyId: number) =>
    `${NOTIFICATION_BASE_URL}/read/notification/all/${companyId}`,
  UPDATE_COMPANY: (id: number) => `${BASE_URL}/company/${id}`,
  GET_COMPANY_DOCUMENT: (id: number) => `${BASE_URL}/company/documents/${id}`,
  ADD_DIRECTOR: (id: number) => `${BASE_URL}/company/director/details/${id}`,
  UPLOAD_PAYMENT: `${BASE_URL}/upload`,
  RESEND_UPLOAD: `${BASE_URL}/upload/resend`,
  VIEW_UPLOADS: `${BASE_URL}/approvals`,
  VIEW_UPLOAD: (batchId: string) =>
    `${BASE_URL}/approvals/batch/details/${batchId}`,
  VIEW_UPLOAD_COMMENTS: (id: number) => `${BASE_URL}/approvals/${id}/comments`,
  GET_USERS: (page: number, size: number) =>
    `${BASE_URL}/unsecure/user?page=${page}&size=${size}&sortOrder=DESC`,
  TEAM: `${BASE_URL}/team-member`,
  TEAM_SETTING: `${BASE_URL}/team-member/approval/settings`,
  UPDATE_PROFILE_PICTURE: `${BASE_URL}/user/profile-picture`,
  NOTIFICATION_SETTINGS: `${BASE_URL}/user/notification-settings`,
  APPROVE_REJECT_UPLOAD: (batchId: string) =>
    `${BASE_URL}/approvals/actions/${batchId}`,
  ACTIVATE_WALLET: `${WALLET_BASE_URL}/activate`,
  WALLET_DASHBOARD_DETAILS: `${WALLET_BASE_URL}/dashboard`,
  WALLET_TRANSACTION_HISTORY: `${WALLET_BASE_URL}/transaction/history`,
  WALLET_TRANSACTION_SUMMARY: `${WALLET_BASE_URL}/transaction/summary`,
  WALLET_DETAILS: `${WALLET_BASE_URL}/balance`,
  TRANSACTION_COUNT_YEARLY: `${WALLET_BASE_URL}/transaction/count/yearly`,
  TRANSACTION_COUNT_MONTHLY: `${WALLET_BASE_URL}/transaction/count/monthly`,
  TRANSACTION_COUNT_WEEKLY: `${WALLET_BASE_URL}/transaction/count/weekly`,
  TRANSACTION_COUNT: `${WALLET_BASE_URL}/transaction/count`,
  TRANSACTION_HISTORY: `${PAYMENT_BASE_URL}/transaction/history`,
  TRANSACTION_HISTORY_DETAILS: (id: string) =>
    `${PAYMENT_BASE_URL}/transaction/history/details/${id}`,
  DOWNLOAD_PDF_REPORT: `${PAYMENT_BASE_URL}/transaction/report/pdf`,
  DOWNLOAD_EXCEL_REPORT: `${PAYMENT_BASE_URL}/transaction/report/xlsx`,
  DASHBOARD_COUNT: `${BASE_URL}/admin/dashboard/count`,
  DISABLE_USER: (id: number) => `${BASE_URL}/user/disabled/${id}`,
};
export default endpoints;
