import React, { CSSProperties, FC, useEffect, useState } from "react";
import Text from "../Text";
import { Icon } from "../Icons";

type RadioButtonItems = {
  label: string | JSX.Element;
  value: string;
  disabled?: boolean;
};
type CheckButtonType = {
  items: Array<RadioButtonItems>;
  defaultIndex?: number;
  onChange: (val: string) => void;
  style?: CSSProperties;
  onReset?: () => void;
};
const RadioButton: FC<CheckButtonType> = ({
  items,
  defaultIndex,
  onChange,
  style,
}) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  useEffect(() => {
    if (defaultIndex !== undefined) {
      setSelectedIndex(defaultIndex);
    } else {
      setSelectedIndex(null);
    }
  }, [defaultIndex]);

  const toggle = (index: number, item: RadioButtonItems) => {
    if (item.disabled) return;
    setSelectedIndex(index);
    const selectedItem = items.filter((item, i) => i == index);
    onChange(selectedItem[0].value);
  };
  return (
    <>
      {items.map((item, i) => (
        <div
          style={style ? { ...style } : {}}
          key={i}
          className={`h-[56px] w-[100%] rounded-lg border ${selectedIndex === i ? "border-primary_Blue_600" : "border-gray_200"} ${item.disabled ? "opacity-80 cursor-default" : ""}  flex justify-between items-center px-4 cursor-pointer`}
          onClick={() => toggle(i, item)}
        >
          {typeof item.label === "string" ? (
            <Text
              size={14}
              color={`${selectedIndex === i ? "text-primary_Blue_600" : "text-gray_700"}`}
              classNames="text-medium"
              text={item.label}
            />
          ) : (
            item.label
          )}

          <div
            className={`min-h-5 min-w-5 rounded-2xl flex justify-center items-center ${selectedIndex === i ? "bg-primary_Blue_600 border-primary_Blue_600" : "bg-base_White border-gray_300 border"}`}
          >
            {selectedIndex === i && <Icon name={"button_check"} />}
          </div>
        </div>
      ))}
    </>
  );
};
export default RadioButton;
