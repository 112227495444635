import React, { FC, useState } from "react";
import Drawer from "../Drawer";
import Text from "../Text";
import RadioButton from "../RadioButton";
import Button from "../Button";
import Transfer from "./components/transfer";
import FundByCard from "./components/fundByCard";

interface IFundWalletDrawerProps {
  toggle: () => void;
  show: boolean;
  title: string;
}
const FundWalletDrawer: FC<IFundWalletDrawerProps> = ({
  toggle,
  show,
  title,
}) => {
  const [fundRoute, setFundRoute] = useState("bankTransfer");
  const [cardLoading, setCardLoading] = useState(false);
  const [cardStep, setCardStep] = useState(0);
  const [cardSuccess, setCardSuccess] = useState(false);

  const handleRouteChange = (val: string) => {
    setCardStep(0);
    setFundRoute(val);
  };
  const onClose = () => {
    setCardStep(0);
    setCardSuccess(false);
    setFundRoute("bankTransfer");
    toggle();
  };
  const cardButtonClick = () => {
    switch (cardStep) {
      case 0:
        // submit card information
        setCardLoading(true);

        setTimeout(() => {
          // do other things
          setCardStep((prev) => {
            if (prev === 1) return prev;
            return prev + 1;
          });
          setCardSuccess(true);
          setCardLoading(false);
        }, 5000);
        break;
      case 1:
        onClose();
        break;
    }
  };
  const handleClick = () => {
    switch (fundRoute) {
      case "bankTransfer":
        return;
      case "card":
        cardButtonClick();
        break;
    }
  };

  return (
    <Drawer
      toggle={toggle}
      show={show}
      showFooterButton={fundRoute === "card"}
      footerButton={
        !cardLoading ? (
          <div className="m-auto">
            <Button
              label={cardStep === 1 ? "Close" : "Fund wallet"}
              variant="primary"
              onClick={handleClick}
              classNames="w-[179px]"
            />
          </div>
        ) : null
      }
      title={title}
    >
      <section className="px-[15px] py-5">
        <div className="flex flex-col gap-[21px]">
          {!cardLoading && !cardSuccess && (
            <div className="flex flex-col gap-1">
              <Text
                size={14}
                text="Select funding method"
                classNames="font-medium"
                color="text-gray_700"
              />
              <div className="flex flex-row gap-[13px]">
                <RadioButton
                  items={[
                    { label: "Bank Transfer", value: "bankTransfer" },
                    { label: "Card", value: "card", disabled: true },
                  ]}
                  defaultIndex={0}
                  onChange={handleRouteChange}
                />
              </div>
            </div>
          )}

          {fundRoute === "bankTransfer" && <Transfer />}
          {fundRoute === "card" && (
            <FundByCard loading={cardLoading} success={cardSuccess} />
          )}
        </div>
      </section>
    </Drawer>
  );
};

export default FundWalletDrawer;
