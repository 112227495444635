import React, { Dispatch, FC, SetStateAction } from "react";
import Modal from "../../../components/Modal";
import Button from "../../../components/Button";
import { Icon } from "../../../components/Icons";
import Text from "../../../components/Text";
import { ILoginResponseData } from "../../../interface/auth";

interface IVerifiedProps {
  toggle: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  user: ILoginResponseData["user"];
}
const VerifiedModal: FC<IVerifiedProps> = ({ toggle, setShow, show, user }) => {
  const footerButton = () => {
    return (
      <div className="flex flex-row flex-1">
        <Button
          label="Okay, I got it"
          variant="primary"
          style={{ width: "100%" }}
          onClick={toggle}
        />
      </div>
    );
  };
  return (
    <Modal
      title="Business verified"
      toggle={toggle}
      setShow={setShow}
      show={show}
      footerElement={footerButton()}
      footerClasses="flex items-center justify-center py-[30px] px-[31px] border-t border-t-[#F3F2F2]"
    >
      <div className="flex flex-col gap-[10px] items-center">
        <Icon name="verified_tick" />
        <div className="text-center w-[85%]">
          <Text
            text={`Congratulations ${user?.company?.name ?? "-"} !!!`}
            color="text-gray_900"
            size={18}
            classNames="font-medium"
          />

          <Text
            text="We have successfully verified your business. You can now activate your wallet, fund your account and make bulk payments."
            size={14}
            color="text-gray_800"
          />
        </div>
      </div>
    </Modal>
  );
};

export default VerifiedModal;
