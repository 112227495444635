import { IError } from "../interface/error";

export const getError = async (data: Response): Promise<Error> => {
  const status = data.status;
  if (data.text) {
    const dd = await data.text();
    if (dd) {
      const error: IError = JSON.parse(dd);
      if (error.auth === false) {
        throw {
          message: error.message,
          status: status,
        };
      } else {
        throw {
          message: error.message,
          status: error.status || status,
        };
      }
    } else {
      throw {
        message: "An error occurred, please try again later.",
      };
    }
  } else {
    throw {
      message: "An error occurred, please try again later.",
    };
  }
};

export const handleError = (
  error: unknown,
  toast: {
    error: (message: string) => void;
    success: (message: string) => void;
  },
  message?: string
) => {
  const err = error as IError;
  toast.error(message ?? err.message);
};
