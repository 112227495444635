import React from "react";
import Text from "../Text";

const Progress = () => {
  return (
    <div className="flex flex-row w-[100%] items-center gap-3">
      <div className="rounded bg-gray_100 w-[100%] lg:w-[310px] h-2">
        <div
          className="h-2 bg-primary_Blue_600 rounded-full"
          style={{ width: "50%" }}
        />
      </div>
      <Text
        text="1/2"
        color="text-gray_700"
        size={14}
        classNames="font-medium"
      />
    </div>
  );
};

export default Progress;
