import React, { useState } from "react";
import Title from "../../components/Title";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD, LOGIN } from "../../routes/navigations";
import { useValidation } from "../../hooks/useValidation";
import { changePasswordValidation } from "../../utils/validations";
import { IError } from "../../interface/error";
import useToast from "../../hooks/useToast";
import api from "../../network/api";

const SetNewPassword = () => {
  const navigate = useNavigate();
  const state = useLocation().state as { otp: string };
  const toast = useToast();
  const { addData, data, validated, validationResult } = useValidation(
    changePasswordValidation
  );
  const [loading, setLoading] = useState(false);

  async function setPassword() {
    if (!state.otp) {
      toast.error("OTP not found");
      navigate(-1);
      return;
    }
    setLoading(true);
    try {
      const response = await api.forgotPasswordActivation(
        state.otp,
        data.password
      );
      toast.success("Password changed successfully");
      navigate(LOGIN);
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "");
    }
    setLoading(false);
  }

  function handleChange(name: string, value: string) {
    addData(name, value);
  }
  return (
    <div className="w-[100%] flex justify-center my-12">
      <div className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6">
        <Title
          title="Set new password"
          icon="mail"
          description="Your new password must be different to previously used passwords."
        />
        <div className="flex flex-col mt-[8px] w-[100%] gap-5">
          <TextInput
            label="New Password"
            id="regNewPassword"
            placeholder="Enter new password"
            name="password"
            type="password"
            onInputChange={handleChange}
            error={validationResult.password}
          />
          <TextInput
            label="Confirm Password"
            id="regConfirmPassword"
            placeholder="Confirm new password"
            name="passwordConfirmation"
            type="password"
            onInputChange={handleChange}
            error={validationResult.passwordConfirmation}
          />
          <Button
            variant="primary_lg"
            label="Login"
            classNames="w-[100%]"
            loading={loading}
            disabled={!validated}
            onClick={setPassword}
          />
        </div>
      </div>
    </div>
  );
};
export default SetNewPassword;
