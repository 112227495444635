import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import { DASHBOARD, LOGIN, REGISTER } from "../routes/navigations";
import { useAppSelector } from "../hooks/redux";
import { selectAuthenticationToken } from "../redux/features/authSlice";
import SuspenseLoader from "../components/SuspenseLoader";

const PublicContainer = () => {
  const path = useLocation().pathname;
  const navigate = useNavigate();
  const authToken = useAppSelector(selectAuthenticationToken);

  const [loading, setLoading] = useState(true);
  // if (authState.authenticated) {
  //   return <Navigate to={HOME} replace />;
  // }

  useEffect(() => {
    if (authToken) {
      navigate(DASHBOARD, { replace: true });
    } else {
      setLoading(false);
    }
  }, [authToken]);

  const handleBackToWebsite = () => {
    window.location.href = "https://www.swiftlink.ng/";
  };

  if (loading) return <SuspenseLoader />;

  return (
    <div>
      <header className="bg-white font-Euclid border-b border-b-gray_100">
        <nav
          className="mx-auto flex-col lg:flex-row flex max-w-7xl lg:items-center justify-between p-6 lg:px-8 "
          aria-label="Global"
        >
          <div className="lg:flex flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className=" w-auto"
                src="/logo2.png"
                style={{ height: 50 }}
                alt=""
              />
            </a>
          </div>

          {(path === REGISTER || path === LOGIN) && (
            <div className="flex flex-1 justify-end w-[100%]">
              <Button label="Back to website" onClick={handleBackToWebsite} />
            </div>
          )}
        </nav>
      </header>
      <Outlet />
    </div>
  );
};

export default PublicContainer;
