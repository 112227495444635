import React, { FC, useEffect, useState } from "react";
import Button from "../../components/Button";
import { Icon } from "../../components/Icons";
import PageTitle from "../../components/PageTitle";
import Table from "../../components/Table";
import Text from "../../components/Text";
import Unverified from "./components/unverified";
import StatTiles, {
  StatTilesComponent,
  TileType,
} from "../../components/StatTiles";
import FundWalletDrawer from "../../components/FundwalletDrawer";
import { useNavigate } from "react-router-dom";
import {
  BUSINESS_DETAILS,
  TRANSACTIONS,
  UPLOADS,
} from "../../routes/navigations";
import VerificationInProgress from "./components/verificationInProgress";
import UploadPaymentDrawer from "../uploads/components/uploadPaymentsDrawer";
import AccessControl from "../../components/AccessControl";
import {
  clientPermissions,
  clientRoles,
  selectRoleAndPermission,
  selectUser,
  setUser,
} from "../../redux/features/authSlice";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useToast from "../../hooks/useToast";
import {
  getBusinessInReviewStatus,
  selectWalletStat,
  setWalletStat,
} from "../../redux/features/globalSlice";
import { handleError } from "../../utils/error";
import api from "../../network/api";
import { IGetUploads } from "../../interface/upload";
import { IQuery } from "../../interface/network";
import StatusComponent from "../../components/StatusComponent";
import {
  capitalize,
  copyToClipboard,
  dateAgo,
  downloadFile,
  formatCash,
  formatDate,
  removeUnderscore,
} from "../../utils/utilMethods";
import { useRoleElement } from "../../hooks/useRoleElement";
import ViewUpload from "../uploads/components/viewUpload";
import { ITransaction } from "../../interface/transaction";
import TransactionDetails from "../transactions/components/transactionDetails";
import { v4 as uuid } from "uuid";
import {
  getWalletDetails,
  selectDashboardCount,
  setDashboardCount,
  setWalletDetails,
} from "../../redux/features/paymentSlice";

const Dashboard = () => {
  const navigate = useNavigate();
  const user = useAppSelector(selectUser);
  const toast = useToast();
  const dispatch = useAppDispatch();
  const walletStat = useAppSelector(selectWalletStat);
  const walletDetails = useAppSelector(getWalletDetails);
  const dashboardCount = useAppSelector(selectDashboardCount);

  const inReviewStatus = useAppSelector(getBusinessInReviewStatus);
  const userRoleAndPermissions = useAppSelector(selectRoleAndPermission);

  const { getRoleElement } = useRoleElement();

  const [showFundWallet, setShowFundWallet] = useState(false);

  const [showUploadDrawer, setShowUploadDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tsLoading, setTsLoading] = useState(false);
  const [uploads, setUploads] = useState<IGetUploads["content"]>([]);
  const [upload, setUpload] = useState<IGetUploads["content"][0] | undefined>();
  const [showViewUpload, setShowViewUpload] = useState(false);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [selectedTransaction, setSelectedTransaction] = useState<any>();
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (!walletStat) getWalletDashboardDetails();
    if (!walletDetails) fetchWalletDetails();

    getTransactions();
  }, []);

  useEffect(() => {
    if (
      userRoleAndPermissions &&
      userRoleAndPermissions.role !== clientRoles.superAdmin
    ) {
      getUploads();
      if (!dashboardCount) getDashabordCount();
    } else {
      //get transactions
    }
  }, [userRoleAndPermissions]);

  const togglePaymentDetails = () => setShowViewUpload((prev) => !prev);
  const toggleDetails = () => setShowDetails((prev) => !prev);

  const toggleFundWallet = () => setShowFundWallet((prev) => !prev);
  const togglePaymentDrawer = () => {
    setShowUploadDrawer((prev) => !prev);
  };

  const getDashabordCount = async () => {
    setLoading(true);
    try {
      const response = await api.getDashboardCount();
      if (response.data) dispatch(setDashboardCount(response.data));
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };

  async function fetchWalletDetails() {
    if (!user?.company.walletActivation) return;

    try {
      const response = await api.getWalletDetails();

      if (response.data) dispatch(setWalletDetails(response.data));
    } catch (error) {
      handleError(error, toast);
    }
  }

  async function getUploads() {
    try {
      setLoading(true);

      const response = await api.viewUploads({
        page: "1",
        size: "10",
      });

      if (response?.data?.content) setUploads(response.data?.content);
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  }

  const openSelectedUpload = (selectedUpload: IGetUploads["content"]["0"]) => {
    setUpload(selectedUpload);
    togglePaymentDetails();
  };

  const getWalletDashboardDetails = async () => {
    if (!user?.company.walletActivation) return;
    setLoading(true);
    try {
      const response = await api.getWalletDashboardDetails();

      if (response.data) dispatch(setWalletStat(response.data));
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };

  const getTransactions = async (queries?: IQuery) => {
    try {
      setTsLoading(true);

      const startDateQuery = "2024-01-01";
      const endDateQuery = formatDate(new Date(), "YYYY-MM-DD");
      const response = await api.getTransactionHistory({
        page: "1",
        size: "10",
        startDate: startDateQuery,
        endDate: endDateQuery,
        ...(queries?.search && { search: queries.search }),
      });
      if (response.data) {
        setTransactions(response.data?.content);
      }
    } catch (error) {
      handleError(error, toast);
    }
    setTsLoading(false);
  };

  const handleClick = (selected: ITransaction) => {
    setSelectedTransaction({
      "Transaction date": formatDate(
        selected?.createdAt,
        "DD MMM, YYYY HH:mm a"
      ),
      Narration: selected.narration,
      Amount: `NGN${formatCash(selected.totalAmount)}`,
      "Payment method": removeUnderscore(selected.channelId),
      "Transaction reference": selected.batchId,
      "Transaction Type": capitalize(selected.paymentType),
      // Sender: `-`,
    });
    toggleDetails();
  };

  const copy = async (text: string) => {
    try {
      await copyToClipboard(text);
      toast.success("Copied successfully");
    } catch (error) {
      handleError(error, toast);
    }
  };

  const setUploadTable = () => {
    let theData: any = [];
    let count = 0;
    if (!uploads.length) return [];
    for (const i in uploads) {
      const layer = [
        <td className="py-4 px-6" key={uploads[i].id}>
          {uploads[i].title}
        </td>,
        <td className="py-4 px-6" key={uploads[i].id}>
          <StatusComponent status={removeUnderscore(uploads[i].status)} />
        </td>,

        <td className="py-4 px-6" key={uploads[i].id}>
          {capitalize(uploads[i].addedBy)}
        </td>,
        <td className="py-4 px-6" key={uploads[i].id}>
          {getRoleElement(uploads[i]?.currentApprovalStage?.name ?? "-")}
        </td>,
        <td className="py-4 px-6" key={uploads[i].id}>
          {formatDate(uploads[i].createdAt, "MMMM DD, YYYY")}
        </td>,
        <td className="py-4 px-6 min-w-[180px]" key={uploads[i].id}>
          {dateAgo(uploads[i].updatedAt)}
        </td>,
        <td className="py-4 px-6" key={uploads[i].id}>
          <span
            className="cursor-pointer"
            onClick={() => downloadFile(uploads[i].filePath)}
          >
            <Icon name="download_file" />
          </span>
        </td>,
        <td className="py-4 px-6" key={uploads[i].id}>
          <span
            className="cursor-pointer"
            onClick={() => openSelectedUpload(uploads[i])}
          >
            <Icon name="copy" />
          </span>
        </td>,
      ];
      theData = [...theData, layer];

      count += 1;
      if (count === uploads.length) {
        return theData;
      }
    }
  };

  const setTable = () => {
    let theData: any = [];
    let count = 0;

    for (const i in transactions) {
      if (!transactions.length) return [];
      const layer = [
        <td className="py-4 px-6" key={transactions[i].id}>
          {transactions[i].narration}
        </td>,
        // <td className="py-4 px-6" key={transactions[i].id}>
        //   -
        // </td>,
        <td className="py-4 px-6" key={transactions[i].id}>
          <div className="flex gap-4">
            <span>{transactions[i].batchId}</span>
            <div
              className="cursor-pointer"
              onClick={() => copy(transactions[i].batchId)}
            >
              <Icon name="id_copy" />
            </div>
          </div>
        </td>,
        <td className="py-4 px-6" key={transactions[i].id}>
          ₦{formatCash(transactions[i].totalAmount)}
        </td>,
        <td className="py-4 px-6" key={transactions[i].id}>
          {capitalize(transactions[i].paymentType)}
        </td>,
        <td className="py-4 px-6" key={transactions[i].id}>
          {removeUnderscore(transactions[i].channelId)}
        </td>,
        <td className="py-4 px-6 min-w-[180px]" key={transactions[i].id}>
          {formatDate(transactions[i].createdAt, "DD MMM, YYYY")}
        </td>,
        <td className="py-4 px-6" key={transactions[i].id}>
          <span
            className="cursor-pointer"
            onClick={() => handleClick(transactions[i])}
          >
            <Icon name="copy" />
          </span>
        </td>,
      ];
      theData = [...theData, layer];

      count += 1;
      if (count === transactions.length) {
        return theData;
      }
    }
  };

  const getTransactionEmptyState = () => {
    if (userRoleAndPermissions?.role === clientRoles.superAdmin) {
      return {
        title: "No transaction yet",
        description: "All your transaction will show here once made",
      };
    }

    if (
      userRoleAndPermissions?.role === clientRoles.reviewer ||
      userRoleAndPermissions?.role === clientRoles.approver
    ) {
      return {
        title: "No upload yet",
        description: "All your transaction will show here once made",
      };
    }

    if (userRoleAndPermissions?.role === clientRoles.initiator) {
      return {
        buttonText: "Initiate bulk payment",
        title: "No transaction yet",
        description: "All your transaction will show here once made",
        buttonAction: togglePaymentDrawer,
      };
    }
  };

  const getUser = () => {
    api
      .getUser()
      .then((res) => {
        dispatch(setUser(res));
      })
      .catch((err) =>
        toast.error(err.message ?? "Error getting user information")
      );
  };

  const activateWallet = async () => {
    try {
      setLoading(true);
      const response = await api.activateWallet({
        reference: uuid(),
        channelId: "FidelityVirtual",
      });
      getUser();
      toast.success(response.message);
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };

  return (
    <div>
      <PageTitle
        title={`Welcome back, ${user?.firstName ?? "-"}`}
        description="Manage your activities and payments with Swiftmonie"
        buttonOneLable={user?.company.walletActivation ? "Fund wallet" : ""}
        buttonTwoLabel="Initate bulk payment"
        onClickButtonTwo={togglePaymentDrawer}
        onClickButtonOne={toggleFundWallet}
        buttonOnePermission={[clientPermissions.FUND_WALLET]}
        buttonTwoPermission={[clientPermissions.CREATE_UPLOAD]}
      />
      <AccessControl requiredPermission={[clientPermissions.VIEW_VERIFICATION]}>
        {((user?.company.active === "NONE" &&
          !user?.company.documentUploadStatus) ||
          user?.company.active === "REJECTED") && (
          <Unverified onVerify={() => navigate(BUSINESS_DETAILS)} />
        )}
        {user?.company.documentUploadStatus &&
          user?.company.active === "NONE" && <VerificationInProgress />}
      </AccessControl>

      <AccessControl requiredPermission={[clientPermissions.VIEW_WALLET]}>
        <div className="pb-5 flex flex-col lg:flex-row gap-6">
          <StatTiles
            type={TileType.walletBalance}
            value={formatCash(walletStat?.walletBalance) ?? "-"}
            loading={loading}
            activateWallet={activateWallet}
          />
          <StatTiles
            type={TileType.transactionVolume}
            value={`₦ ${formatCash(walletStat?.transactionVolume) ?? "-"}`}
            loading={loading}
          />
          <StatTiles
            type={TileType.transactionCount}
            value={walletStat?.transactionCount.toLocaleString() ?? "-"}
            loading={loading}
          />
        </div>
      </AccessControl>

      <AccessControl requiredPermission={[clientPermissions.VIEW_UPLOAD]}>
        <div className="pb-5 flex flex-col lg:flex-row gap-6">
          <StatTilesComponent
            icon="dashboard_wallet"
            textOne="Payment Uploads"
            textTwo={
              loading
                ? "loading..."
                : dashboardCount?.uploadsCount?.toLocaleString() ?? "-"
            }
          />
          <StatTilesComponent
            icon="transaction_count"
            textOne="Approved Uploads"
            textTwo={
              loading
                ? "loading..."
                : dashboardCount?.totalUploadSuccess?.toLocaleString() ?? "-"
            }
          />
          <StatTilesComponent
            icon="rejected_uploads"
            textOne="Rejected Uploads"
            textTwo={
              loading
                ? "loading..."
                : dashboardCount?.totalUploadFailed?.toLocaleString() ?? "-"
            }
          />
        </div>
      </AccessControl>

      <div className="pb-5 flex flex-row items-center justify-between border-b border-gray_200">
        <Text
          text={
            userRoleAndPermissions?.role === clientRoles.superAdmin ||
            userRoleAndPermissions?.role === clientRoles.initiator
              ? "Transaction history"
              : "Uploads"
          }
          size={18}
          classNames="font-medium"
          color="text-gray_900"
        />
        <div>
          <Button
            label={
              userRoleAndPermissions?.role === clientRoles.superAdmin ||
              userRoleAndPermissions?.role === clientRoles.initiator
                ? "See all transactions"
                : "See all uploads"
            }
            onClick={() => {
              userRoleAndPermissions?.role === clientRoles.superAdmin ||
              userRoleAndPermissions?.role === clientRoles.initiator
                ? navigate(TRANSACTIONS)
                : navigate(UPLOADS);
            }}
          />
        </div>
      </div>

      {userRoleAndPermissions &&
        (userRoleAndPermissions.role === clientRoles.reviewer ||
          userRoleAndPermissions.role === clientRoles.approver) && (
          <Table
            headers={[
              <th className="px-6 py-3" key={1}>
                Upload name
              </th>,
              <th className="px-6 py-3" key={2}>
                Status
              </th>,
              <th className="px-6 py-3" key={3}>
                Added by
              </th>,
              <th className="px-6 py-3" key={3}>
                Role
              </th>,
              <th className="px-6 py-3" key={3}>
                Date created
              </th>,
              <th className="px-6 py-3" key={7}>
                Last updated
              </th>,
            ]}
            emptyStateProps={{
              buttonText: "",
              title: "No upload yet",
              buttonAction: togglePaymentDrawer,
            }}
            loading={loading}
            data={setUploadTable()}
            hidePagination
            callBack={getUploads}
            showFilter={false}
          />
        )}

      {userRoleAndPermissions &&
        (userRoleAndPermissions.role === clientRoles.superAdmin ||
          userRoleAndPermissions.role === clientRoles.initiator) && (
          <Table
            headers={[
              <th className="px-6 py-3" key={1}>
                Narration
              </th>,
              // <th className="px-6 py-3" key={2}>
              //   Recipient
              // </th>,
              <th className="px-6 py-3" key={3}>
                Transaction ID
              </th>,
              <th className="px-6 py-3" key={3}>
                Amount
              </th>,
              <th className="px-6 py-3" key={3}>
                Type
              </th>,
              <th className="px-6 py-3" key={7}>
                Method
              </th>,
              <th className="px-6 py-3" key={8}>
                Date & Time
              </th>,
            ]}
            hideSearch
            data={setTable()}
            hidePagination
            emptyStateProps={getTransactionEmptyState()}
            loading={tsLoading}
            showFilter={false}
          />
        )}

      {/* drawer */}
      <FundWalletDrawer
        title="Fund Wallet"
        toggle={toggleFundWallet}
        show={showFundWallet}
      />
      <UploadPaymentDrawer
        toggle={togglePaymentDrawer}
        show={showUploadDrawer}
        title="Initiate bulk payment"
      />

      {upload && (
        <ViewUpload
          toggle={togglePaymentDetails}
          show={showViewUpload}
          title={upload.title}
          upload={upload}
          callBack={getUploads}
        />
      )}

      {selectedTransaction && (
        <TransactionDetails
          title="Transaction details"
          show={showDetails}
          toggle={toggleDetails}
          data={selectedTransaction}
        />
      )}
    </div>
  );
};

export default Dashboard;
