import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "../components/Button";
import PageTitle from "../components/PageTitle";
import StepIndicator from "../components/StepIndicator";
import Text from "../components/Text";
import {
  BUSINESS_DETAILS,
  BUSINESS_DOCUMENTS,
  BUSINESS_SUCCESS,
  DASHBOARD,
  DIRECTOR_DETAILS,
  LOGIN,
  REVIEW_BUSINESS_INFO,
} from "../routes/navigations";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import {
  getBusinessDetails,
  getBusinessUploads,
  getDirectorDetails,
  getLoading,
  setGlobalLoading,
} from "../redux/features/globalSlice";
import {
  selectAuthenticationToken,
  selectUser,
  setUser,
} from "../redux/features/authSlice";
import api from "../network/api";
import useToast from "../hooks/useToast";
import SuspenseLoader from "../components/SuspenseLoader";

const VerificationContainer = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const authToken = useAppSelector(selectAuthenticationToken);
  const globalLoading = useAppSelector(getLoading);
  const user = useAppSelector(selectUser);
  const businessDetails = useAppSelector(getBusinessDetails);
  const savedDirectors = useAppSelector(getDirectorDetails);
  const businessUploads = useAppSelector(getBusinessUploads);

  const toast = useToast();

  const path = useLocation().pathname;

  const [loading, setLoading] = useState(true);

  const [steps, setSteps] = useState([
    { label: "Business Details", path: BUSINESS_DETAILS, done: false },
    { label: "Director’s Details", path: DIRECTOR_DETAILS, done: false },
    { label: "Business Documents", path: BUSINESS_DOCUMENTS, done: false },
    { label: "Review", path: REVIEW_BUSINESS_INFO, done: false },
  ]);

  useEffect(() => {
    const savedSteps = [...steps];
    if (businessDetails) {
      savedSteps.splice(0, 1, { ...steps[0], done: true });
    }
    if (savedDirectors.length) {
      savedSteps.splice(1, 1, { ...steps[1], done: true });
    }
    if (businessUploads.length) {
      savedSteps.splice(2, 1, { ...steps[2], done: true });
    }
    setSteps([...savedSteps]);
  }, [businessDetails, savedDirectors, businessUploads]);

  useEffect(() => {
    if (!user && authToken) {
      dispatch(setGlobalLoading(true));
      api
        .getUser()
        .then((res) => {
          dispatch(setUser(res));
        })
        .catch((err) =>
          toast.error(err.message ?? "Error getting user information")
        )
        .finally(() => dispatch(setGlobalLoading(false)));
    }
  }, [user]);

  useEffect(() => {
    if (!authToken) {
      navigate(LOGIN, { replace: true });
    } else {
      setLoading(false);
    }
  }, [authToken]);

  useEffect(() => {
    if (user?.company?.active === "ACCEPTED") {
      navigate(DASHBOARD, { replace: true });
    }
  }, [user?.company.active]);

  const checkIfCanReview = () => {
    if (!businessDetails || !savedDirectors.length || !businessUploads.length) {
      return false;
    } else {
      return true;
    }
  };

  const getStepIndicatorType = (stepPath: string, done: boolean) => {
    if (done) return "done";
    if (path === stepPath) {
      return "normal";
    }
    return "disabled";
  };

  const gotoPage = (path: string) => {
    navigate(path);
  };
  if (loading || globalLoading) return <SuspenseLoader />;

  if (path === BUSINESS_SUCCESS) {
    return (
      <div>
        <header className="bg-white font-Euclid border-b border-b-gray_100">
          <nav
            className="mx-auto flex-col lg:flex-row flex max-w-7xl h-[70px] lg:items-center justify-between p-6 lg:px-8 "
            aria-label="Global"
          >
            <div className="lg:flex flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                <img
                  className=" w-auto"
                  src="/logo2.png"
                  style={{ height: 50 }}
                  alt=""
                />
              </a>
            </div>
          </nav>
        </header>
        <div>
          <Outlet />
        </div>
      </div>
    );
  }
  return (
    <div>
      <header className="bg-white font-Euclid border-b border-b-gray_100">
        <nav
          className="mx-auto flex-col lg:flex-row flex max-w-7xl h-[70px] lg:items-center justify-between p-6 lg:px-8 "
          aria-label="Global"
        >
          <div className="lg:flex flex lg:flex-1">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <img
                className=" w-auto"
                src="/logo2.png"
                style={{ height: 50 }}
                alt=""
              />
            </a>
          </div>

          <div className="flex flex-1 justify-end w-[100%]">
            <Button
              label="Back to dashboard"
              onClick={() => navigate(DASHBOARD)}
            />
          </div>
        </nav>
      </header>
      <main className="py-[28px] px-[40px] lg:py-[48px] lg:px-[80px]">
        <div className="mb-[32px]">
          <PageTitle
            title="Business verification"
            description="Start your business verification, this will enable you to use Swiftmonie to it’s fullest"
          />
        </div>
        <div className="lg:flex flex-row gap-[58px]">
          <div className="w-[343px] mb-7 lg:mb-0 flex flex-col gap-2 items-start">
            {steps.map((step, i) => (
              <div
                className="flex flex-row gap-3 cursor-pointer"
                key={i + step.label}
                onClick={() => {
                  if (step.label === "Review" && checkIfCanReview()) {
                    gotoPage(step.path);
                  } else if (step.label !== "Review") {
                    gotoPage(step.path);
                  }
                }}
              >
                <div className="flex flex-col items-center">
                  <StepIndicator
                    type={getStepIndicatorType(step.path, step.done)}
                  />
                  {i !== steps.length - 1 && (
                    <div
                      className={`h-[14px] w-[2px] ${step.done ? "bg-primary_Blue_600" : "bg-gray_200"} rounded-sm mt-1`}
                    />
                  )}
                </div>
                <div className="mt-1">
                  <Text
                    text={step.label}
                    color={
                      step.path === path
                        ? "text-primary_Blue_600"
                        : "text-gray_700"
                    }
                    size={14}
                    classNames="font-medium"
                  />
                </div>
              </div>
            ))}
          </div>
          <main className="flex-1">
            <Outlet />
          </main>
        </div>
      </main>
    </div>
  );
};

export default VerificationContainer;
