import React, { FormEvent, useEffect, useState } from "react";
import { Icon } from "../../components/Icons";
import TextInput from "../../components/TextInput";
import Text from "../../components/Text";
import PhoneInput from "../../components/PhoneInput";
import DropdownInput from "../../components/DropdownInput";
import Checkbox from "../../components/Checkbox";
import Button from "../../components/Button";
import InfoAndAction from "../../components/infoAndAction";
import { useNavigate } from "react-router-dom";
import { LOGIN, VERIFY_EMAIL } from "../../routes/navigations";
import { useValidation } from "../../hooks/useValidation";
import { registerValidations } from "../../utils/validations";
import { businessTypes } from "../../utils/constants";
import useToast from "../../hooks/useToast";
import { IError } from "../../interface/error";
import api from "../../network/api";
import { IRegistration } from "../../interface/auth";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  selectRegistrationDetails,
  setRegistrationDetails,
} from "../../redux/features/authSlice";

const Register = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const registrationData = useAppSelector(selectRegistrationDetails);
  const { data, addData, validated, validationResult } =
    useValidation(registerValidations);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.values(registrationData).filter((val) => val).length > 0) {
      for (const key in registrationData) {
        addData(key, registrationData[key as keyof typeof registrationData]);
      }
    }
  }, [registrationData]);

  const handleInputChange = (name: string, value: string | boolean) => {
    addData(name, value);
  };
  async function submit(e: FormEvent) {
    e.preventDefault();
    setLoading(true);
    try {
      if (Object.values(registrationData).filter((val) => val).length > 0) {
        navigate(VERIFY_EMAIL);
        return;
      }
      const result = await api.register(data as IRegistration);
      dispatch(setRegistrationDetails(data));
      toast.success("Verify your phone number");
      navigate(VERIFY_EMAIL);
    } catch (error) {
      const err = error as IError;
      toast.error(
        err.message ?? "Unable to register business, please try again later"
      );
    }
    setLoading(false);
  }

  const handleTerms = () => {
    window.open("https://www.swiftlink.ng/terms", "_blank");
  };

  return (
    <div className="w-[100%] flex justify-center my-12">
      <div className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6">
        <Icon name="logo" />
        <Text
          text="Create an account"
          size={30}
          color="text-gray_900"
          classNames="font-medium"
        />
        <form className="flex flex-col mt-[8px] gap-5" onSubmit={submit}>
          <div className="flex flex-row gap-5">
            <TextInput
              label="First Name"
              id="regFirstName"
              placeholder="Enter first name"
              name="firstName"
              error={validationResult.firstName}
              onInputChange={handleInputChange}
              defaultValue={
                registrationData?.firstName ? registrationData?.firstName : ""
              }
            />
            <TextInput
              label="Last Name"
              id="regLastName"
              placeholder="Enter last name"
              name="lastName"
              error={validationResult.lastName}
              onInputChange={handleInputChange}
              defaultValue={
                registrationData?.lastName ? registrationData?.lastName : ""
              }
            />
          </div>
          <TextInput
            label="Email"
            id="regEmail"
            placeholder="Enter your email"
            name="email"
            error={validationResult.email}
            onInputChange={handleInputChange}
            defaultValue={
              registrationData?.email ? registrationData?.email : ""
            }
          />
          <PhoneInput
            label="Phone Number"
            id="regPhone"
            placeholder="Enter your phone number"
            name="phone"
            error={validationResult.phone}
            onInputChange={handleInputChange}
            defaultValue={
              registrationData?.phone ? registrationData?.phone : ""
            }
          />
          <TextInput
            label="Business Name"
            id="regBusinessName"
            placeholder="Acme Corporation LTD"
            name="businessName"
            error={validationResult.businessName}
            onInputChange={handleInputChange}
            defaultValue={
              registrationData?.businessName
                ? registrationData?.businessName
                : ""
            }
          />
          <DropdownInput
            name="businessType"
            label="Business Type"
            placeholder="Select your business type"
            items={businessTypes}
            error={validationResult.businessType}
            selectedValue={registrationData?.businessType ?? ""}
            onChange={(label, value) =>
              handleInputChange("businessType", value)
            }
          />

          <TextInput
            label="Business Email"
            id="regBusEmail"
            placeholder="Enter your business email"
            name="businessEmail"
            error={validationResult.businessEmail}
            onInputChange={handleInputChange}
            defaultValue={
              registrationData?.businessEmail
                ? registrationData?.businessEmail
                : ""
            }
          />
          <PhoneInput
            label="Business Phone Number"
            id="regBusPhone"
            placeholder="Enter your business phone number"
            name="businessPhone"
            error={validationResult.businessPhone}
            onInputChange={handleInputChange}
            defaultValue={
              registrationData?.businessPhone
                ? registrationData?.businessPhone
                : ""
            }
          />

          <Checkbox
            label={
              <div className="text-gray_700 text-sm">
                I accept Swiftmonie&apos;s{" "}
                <span
                  className="text-primary_Blue_600 cursor-pointer"
                  onClick={handleTerms}
                >
                  Terms and Conditions
                </span>
              </div>
            }
            name="terms"
            error={validationResult.termsOfAgreement}
            checked={registrationData?.termsOfAgreement ?? false}
            onChange={(checked) =>
              handleInputChange("termsOfAgreement", checked)
            }
          />
          <Button
            label="Create account"
            variant="primary_lg"
            disabled={!validated}
            loading={loading}
            type="submit"
          />
        </form>

        <InfoAndAction
          info="Already have and account?"
          actionText="Login"
          action={() => navigate(LOGIN)}
        />
      </div>
    </div>
  );
};

export default Register;
