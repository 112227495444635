import React, { FC, useEffect, useState } from "react";
import Drawer from "../../../components/Drawer";
import Text from "../../../components/Text";
import LabelAndValue from "../../../components/LabelAndValue";
import { transactionDetails } from "../../../utils/data";
import StatusComponent from "../../../components/StatusComponent";
import { Icon } from "../../../components/Icons";
import { capitalize, copyToClipboard } from "../../../utils/utilMethods";
import Button from "../../../components/Button";
import { IWalletTransactionData } from "../../../interface/wallet";
import { handleError } from "../../../utils/error";
import useToast from "../../../hooks/useToast";

interface ITransactionDetailsProps {
  toggle: () => void;
  title: string;
  show: boolean;
  data?: any;
  otherInfo?:
    | (typeof transactionDetails)["recipientDetails"]
    | Record<string, never>;
}
const TransactionDetails: FC<ITransactionDetailsProps> = ({
  title,
  show,
  toggle,
  data,
  otherInfo = {},
}) => {
  const toast = useToast();
  const [otherDetails, setOtherDetails] = useState<
    (typeof transactionDetails)["recipientDetails"] | Record<string, never>
  >({});
  const [details, setDetails] = useState<
    typeof transactionDetails | Record<string, never>
  >({});
  useEffect(() => {
    if (data) {
      getDetails();
    }
  }, [data]);

  const copy = async (text: string) => {
    try {
      await copyToClipboard(text);
      toast.success("Copied successfully");
    } catch (error) {
      handleError(error, toast);
    }
  };

  const getDetails = () => {
    let separateData = {};
    let newDetails = {};
    const tsDetails = data;
    // Iterate over the transactionDetails object
    for (const key in tsDetails) {
      // Check if the property is an object (nested object)
      if (typeof tsDetails[key as keyof typeof tsDetails] === "object") {
        for (const nestedKey in tsDetails[
          key as keyof typeof tsDetails
        ] as object) {
          separateData = {
            ...separateData,
            [nestedKey]:
              tsDetails[key as keyof typeof tsDetails][
                nestedKey as keyof (typeof tsDetails)[keyof typeof tsDetails]
              ],
          };
        }
      } else {
        newDetails = {
          ...newDetails,
          [key]: tsDetails[key as keyof typeof tsDetails],
        };
      }
    }
    // setOtherDetails({
    //   "recipient name": data.sender,
    //   "bank name": data.bankName,
    //   "Bank Account": data.bankAccount,
    // });
    setDetails(newDetails);
  };

  const getValue = (key: string, value: string) => {
    switch (key.toLowerCase()) {
      // case "status":
      //   return (
      //     <div className="flex justify-end">
      //       <StatusComponent status={value} />
      //     </div>
      //   );
      case "transaction reference":
        return (
          <div className="flex justify-end gap-4">
            <Text
              text={value}
              size={14}
              color="text-[#252C32]"
              classNames="w-[100%] text-wrap break-words"
            />
            <div className="cursor-pointer" onClick={() => copy(value)}>
              <Icon name="id_copy" />
            </div>
          </div>
        );
      default:
        return value;
    }
  };
  return (
    <Drawer
      title={title}
      show={show}
      toggle={toggle}
      // footerButton={
      //   <div className="flex flex-row mx-auto gap-3">
      //     <Button
      //       label="Download receipt"
      //       variant="primary"
      //       disabled
      //       classNames="min-w-[179px]"
      //     />
      //   </div>
      // }
      // showFooterButton
    >
      <div className="overflow-y-auto">
        <div className="border-b border-gray_200 bg-base_White py-5">
          <div className="px-[15px] ">
            {Object.entries(details).map(([key, value]) => (
              <LabelAndValue
                key={key}
                label={capitalize(key)}
                value={getValue(key, value as string)}
              />
            ))}
          </div>
        </div>
        <div className="px-[25px] py-[17px] ">
          {Object.entries(otherInfo).map(([key, value]) => (
            <LabelAndValue
              key={key}
              label={capitalize(key)}
              value={getValue(key, value as string)}
            />
          ))}
        </div>
      </div>
    </Drawer>
  );
};

export default TransactionDetails;
