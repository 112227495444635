/* eslint-disable no-useless-escape */
export const registerValidations = {
  firstName: (data: Record<string, string>): string => {
    if (!data?.firstName) return "First name is required";
    return "";
  },
  lastName: (data: Record<string, string>): string => {
    if (!data?.lastName) return "Last name is required";
    return "";
  },
  email: (data: Record<string, string>): string => {
    if (!data?.email) return "Email is required";
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      return "Invalid email";
    }

    return "";
  },
  phone: (data: Record<string, string>): string => {
    if (!data.phone) {
      return "Phone number is required";
    }
    if (data.phone && data.phone.length !== 11) {
      return "Phone number should be 11 digits";
    }
    return "";
  },
  businessName: (data: Record<string, string>): string => {
    if (!data?.businessName) return "Business name is required";
    return "";
  },
  businessType: (data: Record<string, string>): string => {
    if (!data?.businessType) return "Business type is required";
    return "";
  },
  termsOfAgreement: (data: Record<string, string>): string => {
    if (!data?.termsOfAgreement)
      return "You need to accept terms and condition";
    return "";
  },
  businessEmail: (data: Record<string, string>): string => {
    if (!data?.businessEmail) return "Business email is required";
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.businessEmail
      )
    ) {
      return "Invalid email";
    }

    return "";
  },
  businessPhone: (data: Record<string, string>): string => {
    if (!data.businessPhone) {
      return "Business phone number is required";
    }
    if (data.businessPhone && data.businessPhone.length !== 11) {
      return "Phone number should be 11 digits";
    }
    return "";
  },
};

export const registrationAuthValidations = {
  password: (data: Record<string, string>): string => {
    if (!data?.password) return "Password is required";
    if (data?.password.length < 8)
      return "Password should be a minimum of 8 characters";
    if (!ConfigPassword.rule.test(data.password)) {
      return "Password must contain at least one uppercase, one lowercase and one special character";
    }
    // if (!ConfigPassword.uppercase.test(data.password))
    //   return "Password must contain at least one uppercase character";
    // if (!ConfigPassword.lowercase.test(data.password))
    //   return "Password must contain at least one lowercase character";
    // if (!ConfigPassword.special.test(data.password))
    //   return "Password must contain at least one special character";
    return "";
  },
  passwordConfirmation: (data: Record<string, string>): string => {
    if (!data?.passwordConfirmation) return "Confirm your password";
    if (data?.passwordConfirmation !== data.password)
      return "Password does not match";
    return "";
  },
  token: (data: Record<string, string>): string => {
    if (!data?.token) return "Token is required";
    return "";
  },
};

export const setPasswordValidation = {
  password: (data: Record<string, string>): string => {
    if (!data.password) {
      return "Password is required";
    }
    return "";
  },
  newPassword: registrationAuthValidations.password,
  passwordConfirmation: (data: Record<string, string>): string => {
    if (!data?.passwordConfirmation) return "Confirm your password";
    if (data?.passwordConfirmation !== data.newPassword)
      return "Password does not match";
    return "";
  },
};

export const loginValidation = {
  password: (data: Record<string, string>): string => {
    if (!data?.password) return "Password is required";
    return "";
  },
  email: (data: Record<string, string>): string => {
    if (!data?.email) return "Email is required";
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      return "Invalid email";
    }

    return "";
  },
};

const ConfigPassword = {
  uppercase: /^(?=.*[A-Z]).+$/,
  lowercase: /^(?=.*[a-z]).+$/,
  special: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/,
  rule: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
};
export const changePasswordValidation = {
  password: (data: Record<string, string>): string => {
    if (!data?.password) return "Password is required";
    if (data?.password.length < 8)
      return "Password should be a minimum of 8 characters";
    if (!ConfigPassword.rule.test(data.password)) {
      return "Password must contain at least one uppercase, one lowercase and one special character";
    }
    // if (!ConfigPassword.uppercase.test(data.password))
    //   return "Password must contain at least one uppercase character";
    // if (!ConfigPassword.lowercase.test(data.password))
    //   return "Password must contain at least one lowercase character";
    // if (!ConfigPassword.special.test(data.password))
    //   return "Password must contain at least one special character";

    return "";
  },
  passwordConfirmation: (data: Record<string, string>): string => {
    if (!data?.passwordConfirmation) return "Confirm your new password";
    if (data?.password !== data.passwordConfirmation)
      return "Password does not match";

    return "";
  },
};

export const businessDetailsValidation = {
  bvn: (data: Record<string, string>): string => {
    if (!data?.bvn) return "BVN is required";
    if (data?.bvn.length !== 11) return "BVN should be 11 digits";

    return "";
  },
  dob: (data: Record<string, string>): string => {
    if (!data?.dob) return "Date of birth is required is required";

    return "";
  },
  sector: (data: Record<string, string>): string => {
    if (!data?.sector) return "Sector is required";
    return "";
  },
  tin: (data: Record<string, string>): string => {
    if (!data?.tin) return "TIN is required";
    return "";
  },
  address: (data: Record<string, string>): string => {
    if (!data?.address) return "Business address is required";
    return "";
  },
};

export const directorDetailsValidation = {
  firstName: (data: Record<string, string>): string => {
    if (!data?.firstName) return "First name is required";

    return "";
  },
  lastName: (data: Record<string, string>): string => {
    if (!data?.lastName) return "Last name is required";
    return "";
  },
  nin: (data: Record<string, string>): string => {
    if (!data?.nin) return "NIN is required";
    if (data?.nin.length !== 11) return "NIN should be 11 digit";
    return "";
  },
  email: (data: Record<string, string>): string => {
    if (!data?.email) return "Email is required";
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      return "Invalid email";
    }
    return "";
  },
};

// GOVT_ISSUED_ID, CAC_CERT, PARTNERSHIP_DEED, MEMART, TAX_ClEARANCE

export const businessDocumentValidations = {
  GOVT_ISSUED_ID: (data: Record<string, string>): string => {
    if (!data?.GOVT_ISSUED_ID) return "Upload your government issued ID";

    return "";
  },
  CAC_CERT: (data: Record<string, string>): string => {
    if (!data?.CAC_CERT) return "Upload your CAC certificate";
    return "";
  },
  PARTNERSHIP_DEED: (data: Record<string, string>): string => {
    if (!data?.PARTNERSHIP_DEED) return "Upload your partnership deed";
    return "";
  },
  MEMART: (data: Record<string, string>): string => {
    if (!data?.MEMART) return "Upload your MEMART certificate";
    return "";
  },
  TAX_ClEARANCE: (data: Record<string, string>): string => {
    if (!data?.TAX_ClEARANCE) return "Upload your tac clearance certificate";
    return "";
  },
};

export const addTeamValidation = {
  firstName: (data: Record<string, string>): string => {
    if (!data?.firstName) return "First name is required";

    return "";
  },
  lastName: (data: Record<string, string>): string => {
    if (!data?.lastName) return "Last name is required";
    return "";
  },
  email: (data: Record<string, string>): string => {
    if (!data?.email) return "Email is required";
    if (
      // eslint-disable-next-line no-useless-escape
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        data.email
      )
    ) {
      return "Invalid email";
    }

    return "";
  },
  phone: (data: Record<string, string>): string => {
    if (!data.phone) {
      return "Phone number is required";
    }
    if (data.phone && data.phone.length !== 11) {
      return "Phone number should be 11 digits";
    }
    return "";
  },
  role: (data: Record<string, string>): string => {
    if (!data?.role) return "Role is required";
    return "";
  },
};

export const validateInput = ({
  data,
  onSubmit = true,
  validations = registerValidations,
}: {
  data: Record<string, any>;
  validations: {
    [key: string]: (data: Record<string, string>) => string;
  };
  onSubmit: boolean;
}): {
  validationResultData?: { [key: string]: string };
  validated: boolean;
} => {
  const dataKeys = Object.keys(validations);
  let validationResultData: { [key: string]: string } = {};
  if (!dataKeys.length) return { validated: false };

  dataKeys.forEach((key) => {
    if (!onSubmit && !data[key]) {
      return false;
    }
    const result = validations[key](data);
    validationResultData = { ...validationResultData, [key]: result };
  });

  const values = Object.values(validationResultData);
  const filteredValues = Object.values(validationResultData).filter(
    (val) => val
  );

  return {
    validationResultData,
    validated: filteredValues.length === 0 && values.length === dataKeys.length,
  };
};
