import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {
  clear,
  getItem,
  removeItem,
  setItem,
  storageKey,
} from "../../utils/storage";
import { ILoginResponseData } from "../../interface/auth";

export enum clientRoles {
  "super admin" = "superAdmin",
  superAdmin = "superAdmin",
  initiator = "initiator",
  approver = "approver",
  reviewer = "reviewer",
}

export enum clientPermissions {
  VIEW_PAYMENT = "VIEW_PAYMENT",
  CREATE_PAYMENT = "CREATE_PAYMENT",
  VIEW_UPLOAD = "VIEW_UPLOAD",
  CREATE_UPLOAD = "CREATE_UPLOAD",

  VIEW_TRANSACTION = "VIEW_TRANSACTION",
  VIEW_VERIFICATION = "VIEW_VERIFICATION",
  CREATE_VERIFICATION = "CREATE_VERIFICATION",
  FUND_WALLET = "FUND_WALLET",
  VIEW_REPORT = "VIEW_REPORT",
  VIEW_SUPPORT = "VIEW_SUPPORT",
  CREATE_SUPPORT = "CREATE_SUPPORT",
  VIEW_WALLET = "VIEW_WALLET",
  UPDATE_WALLET = "UPDATE_WALLET",
  CREATE_TEAM = "CREATE_TEAM",
  UPDATE_TEAM = "UPDATE_TEAM",
  DELETE_TEAM = "DELETE_TEAM",
  VIEW_TEAM = "VIEW_TEAM",
  VIEW_SETTINGS = "VIEW_SETTINGS",
  CREATE_SETTINGS = "CREATE_SETTINGS",
  UPDATE_SETTINGS = "UPDATE_SETTINGS",
}
const initiatorRoleAndPermission = {
  role: clientRoles.initiator,
  permissions: [
    clientPermissions.VIEW_PAYMENT,
    clientPermissions.VIEW_TRANSACTION,
    // "VIEW_VERIFICATION",
    clientPermissions.VIEW_REPORT,
    clientPermissions.CREATE_UPLOAD,
    clientPermissions.VIEW_UPLOAD,
  ],
};
const reviewerRoleAndPermission = {
  role: clientRoles.reviewer,
  permissions: [
    clientPermissions.VIEW_PAYMENT,
    clientPermissions.VIEW_TRANSACTION,
    clientPermissions.FUND_WALLET,
    // clientPermissions.VIEW_WALLET,
    // "VIEW_VERIFICATION",
    clientPermissions.VIEW_REPORT,
    clientPermissions.VIEW_UPLOAD,
  ],
};
const adminRoleAndPermission = {
  role: clientRoles.superAdmin,
  permissions: Object.values(clientPermissions).filter(
    (perm) => !perm.includes("UPLOAD")
  ),
};
// Define a type for the slice state
interface AuthState {
  authenticated: boolean;
  token: string | null;
  user: ILoginResponseData["user"] | null;
  roleAndPermissions: {
    role: clientRoles;
    permissions: string[];
  } | null;
  registrationDetails: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    businessName?: string;
    businessType?: string;
    termsOfAgreement?: boolean;
    businessEmail?: string;
    businessPhone?: string;
    token?: string;
    password?: string;
  };
}

const token = getItem(storageKey.AUTH_TOKEN) as string;
const role = getItem(storageKey.ROLE) as string;

// Define the initial state using that type
const initialState: AuthState = {
  authenticated: token ? true : false,
  token: token ?? "",
  user: null,
  roleAndPermissions: role ? JSON.parse(role) : null,
  registrationDetails: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    businessName: "",
    businessType: "",
    termsOfAgreement: false,
    businessEmail: "",
    businessPhone: "",
    token: "",
    password: "",
  },
};

export const authSlice = createSlice({
  name: "auth",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    authenticate: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.authenticated = true;
      setItem(storageKey.AUTH_TOKEN, action.payload);
    },
    revokeAuthentication: (state) => {
      state.token = "";
      state.authenticated = false;
      state.user = initialState.user;

      // removeItem(storageKey.AUTH_TOKEN);
      // removeItem(storageKey.ROLE);
      clear();
      state.roleAndPermissions = null;
    },
    setRole: (state, action: PayloadAction<clientRoles>) => {
      switch (action.payload) {
        case clientRoles.initiator:
          state.roleAndPermissions = initiatorRoleAndPermission;
          setItem(storageKey.ROLE, JSON.stringify(initiatorRoleAndPermission));
          break;
        case clientRoles.approver:
        case clientRoles.reviewer:
          state.roleAndPermissions = reviewerRoleAndPermission;
          setItem(storageKey.ROLE, JSON.stringify(reviewerRoleAndPermission));

          break;
        case clientRoles["super admin"]:
        case clientRoles.superAdmin:
          state.roleAndPermissions = adminRoleAndPermission;
          setItem(storageKey.ROLE, JSON.stringify(adminRoleAndPermission));

          break;
      }
    },
    setRegistrationDetails: (
      state,
      action: PayloadAction<AuthState["registrationDetails"]>
    ) => {
      state.registrationDetails = {
        ...state.registrationDetails,
        ...action.payload,
      };
    },
    clearRegistrationDetails: (state) => {
      state.registrationDetails = initialState.registrationDetails;
    },
    setUser: (state, action: PayloadAction<ILoginResponseData["user"]>) => {
      state.user = action.payload;
    },
  },
});

export const {
  setRole,
  setRegistrationDetails,
  clearRegistrationDetails,
  authenticate,
  revokeAuthentication,
  setUser,
} = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectRoleAndPermission = (state: RootState) =>
  state.auth.roleAndPermissions;

export const selectRegistrationDetails = (state: RootState) =>
  state.auth.registrationDetails;

export const selectAuthenticationToken = (state: RootState) => state.auth.token;
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthState = (state: RootState) => state.auth;
export default authSlice.reducer;
