import React from "react";
import { BulletList } from "react-content-loader";
import SuspenseLoader from "../SuspenseLoader";
import { PropagateLoader } from "react-spinners";

export const BulletListLoader = () => (
  <div className="px-[26px] py-[10px]">
    <BulletList
      title="loading"
      foregroundColor="#48B9FF"
      backgroundColor="#EDF8FF"
    />
  </div>
);

export const PropagateLoaderComponent = () => {
  return (
    <div className="h-[350px] flex items-center justify-center">
      <PropagateLoader color="#0676FF" />
    </div>
  );
};
