import React, { FC } from "react";
import { ToastContainerProps, toast } from "react-toastify";
import { ToastContentProps, ToastProps } from "react-toastify/dist/types";
import Text from "../Text";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface CustomToastProps extends ToastContentProps {
  message: string;
}
interface IToastNotification {
  message: string;
}
export const ToastNotification: FC<IToastNotification> = ({
  message,
  ...props
}) => (
  <div className="flex flex-row min-h-[40px] overflow-x-auto items-center">
    <div className="flex flex-row items-center gap-2">
      <img src="/images/icons/toast-icon.svg" />
      <Text text={message} size={14} classNames="font-medium" />
    </div>

    {/* <button onClick={closeToast}>Close</button> */}
  </div>
);

export const ErrorToastNotification: FC<IToastNotification> = ({
  message,
  ...props
}) => (
  <div className="flex flex-row min-h-[40px] overflow-x-auto items-center">
    <div className="flex flex-row items-center gap-2">
      <img src="/images/icons/toast-error-icon.svg" />
      <Text text={message} size={14} classNames="font-medium" />
    </div>

    {/* <button onClick={closeToast}>Close</button> */}
  </div>
);
