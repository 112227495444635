import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { IGetUpload } from "../../interface/upload";
import {
  IDashboardCountResponseData,
  IWalletDetailsData,
} from "../../interface/wallet";

// Define a type for the slice state
interface PaymentState {
  uploadDetails: IGetUpload["content"];
  walletDetails: IWalletDetailsData | null;
  dashboardCount: IDashboardCountResponseData | null;
}

// Define the initial state using that type
const initialState: PaymentState = {
  uploadDetails: [],
  walletDetails: null,
  dashboardCount: null,
};

export const paymentSlice = createSlice({
  name: "payment",

  initialState,
  reducers: {
    setUploadDetails: (
      state,
      action: PayloadAction<PaymentState["uploadDetails"]>
    ) => {
      state.uploadDetails = action.payload;
    },
    setWalletDetails: (
      state,
      action: PayloadAction<PaymentState["walletDetails"]>
    ) => {
      state.walletDetails = action.payload;
    },
    resetPaymentState: (state) => {
      return { ...initialState };
    },
    setDashboardCount: (state, action: PayloadAction<any>) => {
      state.dashboardCount = action.payload;
    },
  },
});

export const {
  setUploadDetails,
  setWalletDetails,
  resetPaymentState,
  setDashboardCount,
} = paymentSlice.actions;

export const getUploadDetails = (state: RootState) =>
  state.payment.uploadDetails;

export const getWalletDetails = (state: RootState) =>
  state.payment.walletDetails;

export const selectDashboardCount = (state: RootState) =>
  state.payment.dashboardCount;

export default paymentSlice.reducer;
