import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Routing from "./routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routing />
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
