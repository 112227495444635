import { lazy } from "react";
const BusinessDetails = lazy(
  () => import("../Screens/verification/businessDetails")
);
const DirectorDetails = lazy(
  () => import("../Screens/verification/directorDetails")
);
const BusinessDocuments = lazy(
  () => import("../Screens/verification/businessDocuments")
);
const ReviewBusiness = lazy(
  () => import("../Screens/verification/reviewBusiness")
);
const BusinessSuccess = lazy(() => import("../Screens/verification/success"));

import {
  BUSINESS_DETAILS,
  BUSINESS_DOCUMENTS,
  BUSINESS_SUCCESS,
  DIRECTOR_DETAILS,
  REVIEW_BUSINESS_INFO,
} from "./navigations";

const verificationRoutes = [
  { path: BUSINESS_DETAILS, element: BusinessDetails },
  { path: DIRECTOR_DETAILS, element: DirectorDetails },
  { path: BUSINESS_DOCUMENTS, element: BusinessDocuments },
  { path: REVIEW_BUSINESS_INFO, element: ReviewBusiness },
  { path: BUSINESS_SUCCESS, element: BusinessSuccess },
];

export default verificationRoutes;
