import convertSize from "convert-size";
import { IconProps } from "../components/Icons";
import moment from "moment";

export const capitalize = (text: string) => {
  if (!text) return "";
  return text.charAt(0).toUpperCase() + text.substring(1)?.toLowerCase();
};

export const getInitials = (text: string) => {
  if (!text) return "";
  const textArr = text.split(" ");
  return `${textArr[0].charAt(0)?.toUpperCase()}${textArr[textArr.length - 1].charAt(0)?.toUpperCase()}`;
};

export const getFileSize = (file: File): string => {
  if (file) return convertSize(file.size, { accuracy: 1 });
  return "";
};

export const getFileIconName = (file: File): IconProps["name"] => {
  if (file) {
    const type = file.type.split("/")[1];

    if (type === "jpg" || type === "jpeg") return "jpg";

    if (type === "pdf") return "pdf";
    if (type === "csv") return "csv";

    return "otherFile";
  }
  return "otherFile";
};

export const base64ToFile = (base64String: string, fileName: string) => {
  const arr = base64String.split(",");
  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch) {
    throw new Error("Invalid base64 string");
  }
  const mime = mimeMatch[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], fileName, { type: mime });
};

export const removeUnderscore = (text: string) => {
  if (!text) return "";
  return text.replace(/_/g, " ");
};

export const formatDate = (date: string | Date, format = "YYYY-MM-DD") => {
  if (!date) return "";
  return moment(date).format(format);
};

export const dateAgo = (date: string | Date) => {
  return moment(date).fromNow();
};

export const downloadFile = (url: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", ""); // Empty string for the default filename
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const copyToClipboard = async (text: string) => {
  if (!text) return;

  await navigator.clipboard.writeText(text);
};

export const formatCash = (_value: string | number | undefined): string => {
  const value: string = `${_value}`.replace(/[\s,₦%]/g, "");
  if (!value || Number.isNaN(+value)) {
    return "0";
  }
  return `${Number(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const handleAddNumber = (data: number) => {
  if (!data) return 1;
  return data + 1;
};

export function clearObjectValues(obj: Record<string, any>) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj[key] = "";
    }
  }
  return obj;
}
