import { validateInput } from "../utils/validations";
import { useCallback, useEffect, useState } from "react";

export const useValidation = (
  rules: Record<string, (data: Record<string, string>) => string>
) => {
  const [validationRules, setValidationRules] =
    useState<Record<string, (data: Record<string, string>) => string>>(rules);
  const [data, setData] = useState<{ [key: string]: any }>({});
  const [validated, setValidated] = useState(false);
  const [validationResult, setValidationResult] = useState<{
    [key: string]: string;
  }>({});

  const addData = useCallback((name: string, value: any): void => {
    setData((prev) => ({ ...prev, [name]: value }));
  }, []);

  const setRules = useCallback(
    (rules: Record<string, (data: Record<string, string>) => string>): void => {
      setValidationRules(rules);
    },
    []
  );
  const deleteData = useCallback((): void => {
    setData({});
  }, []);

  const validate = (onSubmit = true): boolean => {
    if (validationRules) {
      const validationResponse = validateInput({
        data,
        onSubmit,
        validations: validationRules,
      });
      setValidationResult((prev) => {
        return { ...prev, ...validationResponse.validationResultData };
      });
      return validationResponse.validated;
    }
    return false;
  };

  useEffect(() => {
    let delayDebounceFn: any = null;

    delayDebounceFn = setTimeout(() => {
      const result = validate(false);
      setValidated(result);
    }, 800);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    addData,
    setRules,
    validated,
    validationResult,
    data,
    deleteData,
  };
};
