import React, {
  DetailedHTMLProps,
  Dispatch,
  FC,
  InputHTMLAttributes,
  SetStateAction,
  forwardRef,
  useState,
} from "react";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import { Icon } from "../../components/Icons";
import Text from "../../components/Text";

import DatePickerComponent from "../../components/DatePicker";
import RadioButton from "../../components/RadioButton";

interface IExportModal {
  toggle: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  title?: string;
  message: string;
}

const ViewNotificationModal: FC<IExportModal> = ({
  toggle,
  setShow,
  show,
  title,
  message,
}) => {
  const footerButton = () => {
    return (
      <div className="flex flex-row mx-auto gap-3">
        <Button label="Close" classNames="min-w-[179px]" onClick={toggle} />
      </div>
    );
  };

  return (
    <Modal
      title={title || "Notification details"}
      toggle={toggle}
      setShow={setShow}
      show={show}
      footerElement={footerButton()}
      footerClasses="flex items-center justify-center py-[30px] px-[31px] border-t border-t-[#F3F2F2]"
    >
      <div className="flex flex-col items-center gap-5 w-[100%]">
        <Icon name="notification" />

        <Text text={message} />
      </div>
    </Modal>
  );
};

export default ViewNotificationModal;
