import React, { Dispatch, FC, SetStateAction } from "react";
import Modal from "../Modal";
import Button from "../Button";
import { Icon } from "../Icons";
import Text from "../Text";
import { ILoginResponseData } from "../../interface/auth";

interface IInActiveProps {
  toggle: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  onContinue: () => void;
  onExit: () => void;
  remaningTime: number;
}
const InActiveModal: FC<IInActiveProps> = ({
  toggle,
  setShow,
  show,
  remaningTime,
  onContinue,
  onExit,
}) => {
  const footerButton = () => {
    return (
      <div className="flex flex-row flex-1 gap-3">
        <Button
          label="Logout"
          variant="tertiary"
          style={{
            width: "100%",
            backgroundColor: "#ED3715",
            color: "#ffffff",
          }}
          onClick={onExit}
        />
        <Button
          label="Continue"
          variant="primary"
          style={{ width: "100%" }}
          onClick={onContinue}
        />
      </div>
    );
  };
  return (
    <Modal
      title="Are You Still There?"
      toggle={toggle}
      setShow={setShow}
      show={show}
      footerElement={footerButton()}
      footerClasses="flex items-center justify-center py-[30px] px-[31px] border-t border-t-[#F3F2F2]"
    >
      <div className="flex flex-col gap-[10px] items-center">
        {/* <Icon name="" /> */}
        <img src="/images/icons/toast-error-icon.svg" alt="!" />
        <div className="text-center w-[85%]">
          <Text
            text={`System will sign you out in ${remaningTime} ${remaningTime > 1 ? "seconds" : "second"}.`}
            color="text-gray_900"
            size={18}
            classNames="font-medium"
          />

          <Text
            text="Click on continue to stay signed in,"
            size={14}
            color="text-gray_800"
          />
        </div>
      </div>
    </Modal>
  );
};

export default InActiveModal;
