import React, { FC } from "react";
import { Icon } from "../Icons";

interface IStepIndicatorProps {
  type?: "normal" | "done" | "disabled";
}
const StepIndicator: FC<IStepIndicatorProps> = ({ type = "normal" }) => {
  if (type === "done") {
    return (
      <div className="h-6 w-6 bg-primary_Blue_600 border border-primary_Blue_600 rounded-xl flex items-center justify-center">
        <Icon name="done_check" />
      </div>
    );
  }

  if (type === "disabled") {
    return (
      <div className="h-6 w-6 bg-base_white border-8 border-gray_100 rounded-xl flex items-center justify-center" />
    );
  }

  return (
    <div className="h-6 w-6 bg-primary_Blue_50 border-8 border-primary_Blue_600 rounded-xl" />
  );
};

export default StepIndicator;
