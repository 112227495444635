import React, { useEffect } from "react";
import Text from "../../Text";
import { Icon } from "../../Icons";
import Button from "../../Button";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import {
  getWalletDetails,
  setWalletDetails,
} from "../../../redux/features/paymentSlice";
import { selectUser } from "../../../redux/features/authSlice";
import { handleError } from "../../../utils/error";
import useToast from "../../../hooks/useToast";
import api from "../../../network/api";
import { copyToClipboard } from "../../../utils/utilMethods";

const Transfer = () => {
  const user = useAppSelector(selectUser);
  const walletDetails = useAppSelector(getWalletDetails);
  const dispatch = useAppDispatch();
  const toast = useToast();

  useEffect(() => {
    if (!walletDetails) {
      fetchWalletDetails();
    }
  }, []);

  const fetchWalletDetails = async () => {
    if (!user?.company.walletActivation) return;

    try {
      const response = await api.getWalletDetails();

      if (response.data) dispatch(setWalletDetails(response.data));
    } catch (error) {
      handleError(error, toast);
    }
  };

  const copy = async (text: string) => {
    try {
      await copyToClipboard(text);
      toast.success("Copied successfully");
    } catch (error) {
      handleError(error, toast);
    }
  };
  return (
    <div className="min-h-[292px] flex flex-col gap-6 rounded-lg border border-gray_200 p-5">
      <Text
        size={18}
        color="text-[#252C32]"
        text="Make transfer to your account details below"
        classNames="font-semibold"
      />

      <div className="flex flex-col flex-nowrap justify-center gap-5">
        <div className="flex flex-row flex-nowrap justify-between items-center">
          <Text
            text="Account Name"
            classNames="font-medium"
            size={14}
            color="text-[#252C32]"
          />
          <div className="flex gap-2">
            <Text
              text={`${walletDetails?.walletUser?.firstName ?? "-"} ${walletDetails?.walletUser?.lastName ?? ""}`}
              size={14}
              color="text-[#252C32]"
              classNames="w-[fit-content] text-ellipsis overflow-hidden text-nowrap"
            />
            <div className="cursor-pointer">
              <Icon
                name="id_copy"
                onClick={() => {
                  copy(
                    `${walletDetails?.walletUser?.firstName} ${walletDetails?.walletUser?.lastName}`
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <Text
            text="Bank Name"
            classNames="font-medium"
            size={14}
            color="text-[#252C32]"
          />
          <div className="flex gap-2">
            <Text
              text={walletDetails?.walletUser?.channelId ?? "-"}
              size={14}
              color="text-[#252C32]"
              classNames="w-[fit-content] text-ellipsis overflow-hidden text-nowrap"
            />
            <div className="cursor-pointer">
              <Icon
                name="id_copy"
                onClick={() => copy(walletDetails?.walletUser?.channelId ?? "")}
              />
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-center">
          <Text
            text="Account Number"
            classNames="font-medium"
            size={14}
            color="text-[#252C32]"
          />
          <div className="flex gap-2">
            <Text
              text={walletDetails?.walletUser?.providerAccountNumber ?? ""}
              size={14}
              color="text-[#252C32]"
            />
            <div className="cursor-pointer">
              <Icon
                name="id_copy"
                onClick={() =>
                  copy(walletDetails?.walletUser?.providerAccountNumber ?? "")
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[100%] lg:w-[55%]">
        <Button
          label="Copy account details"
          icon="id_copy"
          iconColor="#ffffff"
          iconPosition="right"
          variant="primary"
          onClick={() => {
            const textsToCopy = [
              `${walletDetails?.walletUser?.firstName} ${walletDetails?.walletUser?.lastName}`,
              walletDetails?.walletUser?.channelId,
              walletDetails?.walletUser?.accountNumber,
            ];
            copy(textsToCopy.join("\n"));
          }}
        />
      </div>
    </div>
  );
};

export default Transfer;
