import React, { FC } from "react";
import { capitalize, removeUnderscore } from "../../utils/utilMethods";
import { Icon } from "../Icons";
import { FailedStatusEnum, SuccessStatusEnum } from "../../interface/upload";

interface IStatusProps {
  status: string;
}

interface IStatusItemProps {
  color?: string;
  background?: string;
  iconColor?: string;
  showIcon?: boolean;
  text: string;
}
export const StatusComponentItem: FC<IStatusItemProps> = ({
  color = "text-warning_700",
  background = "bg-warning_50",
  iconColor = "#B54708",
  showIcon = true,
  text,
}) => {
  return (
    <span
      className={`flex items-center gap-2 ${color} ${background} rounded-full w-[fit-content] px-[8px]`}
    >
      {showIcon && <Icon name="dot" color={iconColor} />}
      {capitalize(text)}
    </span>
  );
};
const StatusComponent: FC<IStatusProps> = ({ status }) => {
  const updatedStatus = status.toLowerCase();

  const uploadSuccessStatusArray = Object.values(SuccessStatusEnum);
  const uploadFailedStatusArray = Object.values(FailedStatusEnum);
  if (
    updatedStatus === "approved" ||
    updatedStatus === "successful" ||
    updatedStatus === "active" ||
    updatedStatus === "resolved" ||
    uploadSuccessStatusArray.includes(
      updatedStatus.toUpperCase() as SuccessStatusEnum
    )
  ) {
    return (
      <StatusComponentItem
        color="text-success_700"
        background="bg-success_50"
        showIcon
        iconColor="#12B76A"
        text={removeUnderscore(status)}
      />
    );
  }
  if (
    updatedStatus === "rejected" ||
    uploadFailedStatusArray.includes(
      updatedStatus.toUpperCase() as FailedStatusEnum
    )
  ) {
    return (
      <StatusComponentItem
        color="text-error_700"
        background="bg-error_50"
        showIcon
        iconColor="#B42318"
        text={removeUnderscore(status)}
      />
    );
  }
  if (updatedStatus === "under review" || updatedStatus === "in review") {
    return (
      <StatusComponentItem
        color="text-warning_700"
        background="bg-warning_50"
        showIcon
        iconColor="#B54708"
        text={removeUnderscore(status)}
      />
    );
  }

  return (
    <StatusComponentItem
      color="text-warning_700"
      background="bg-warning_50"
      showIcon
      iconColor="#B54708"
      text={removeUnderscore(status)}
    />
  );
};

export default StatusComponent;
