import React, { useState } from "react";
import Title from "../../components/Title";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import { useNavigate } from "react-router-dom";
import { FORGOTPASSWORD, VERIFICATION } from "../../routes/navigations";
import useToast from "../../hooks/useToast";
import { IError } from "../../interface/error";
import api from "../../network/api";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");

  async function sendOtp() {
    if (!email) return;
    setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    //   navigate(VERIFICATION, { state: { from: FORGOTPASSWORD } });
    // }, 4000);
    try {
      const response = await api.forgotPassword(email);
      navigate(VERIFICATION, { state: { from: FORGOTPASSWORD, email } });
      toast.success("An Otp has been sent to your email");
    } catch (error) {
      const err = error as IError;
      toast.error(err.message ?? "");
    }
    setLoading(false);
  }
  return (
    <div className="w-[100%] flex justify-center my-12">
      <div className="w-[100%] lg:w-[30%] px-4 lg:px-0 flex flex-col items-center gap-6">
        <Title
          title="Forgot password?"
          icon="create_password_icon"
          description="No worries, we’ll send you reset instructions."
        />
        <div className="w-[100%]">
          <TextInput
            label="Email"
            id="forgotPasswordEmail"
            placeholder="Enter your email"
            name="email"
            type="email"
            onInputChange={(_, value) => setEmail(value)}
          />
        </div>
        <Button
          variant="primary_lg"
          label="Send OTP"
          classNames="w-[100%]"
          disabled={!email}
          loading={loading}
          onClick={sendOtp}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
