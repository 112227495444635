import React, { Dispatch, FC, SetStateAction, useState } from "react";
import Modal from "../Modal";
import TextInput from "../TextInput";
import Button from "../Button";
import useToast from "../../hooks/useToast";
import { handleError } from "../../utils/error";
import { ApprovalActions } from "../../interface/upload";
import api from "../../network/api";

interface ICommentModal {
  toggle: () => void;
  setShow: Dispatch<SetStateAction<boolean>>;
  show: boolean;
  type: ApprovalActions;
  batchId: string;
  callBack?: () => void;
}
const UploadApprovalModal: FC<ICommentModal> = ({
  toggle,
  setShow,
  show,
  type,
  batchId,
  callBack,
}) => {
  const toast = useToast();

  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSend = async () => {
    setLoading(true);
    if (!reason || !batchId || !type) return;
    try {
      const response = await api.approveRejectUpload(batchId, {
        actionType: type,
        reason,
      });

      toast.success(response.message ?? "Action completed successfully");
      if (callBack) callBack();
      toggle();
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };

  const footerButtons = () => {
    return (
      <div className="flex flex-row items-center justify-center gap-3">
        <div>
          <Button label="Cancel" onClick={toggle} classNames="min-w-[179px]" />
        </div>
        <div>
          <Button
            label="Send"
            disabled={!reason}
            variant="primary"
            classNames="min-w-[179px]"
            onClick={handleSend}
            loading={loading}
          />
        </div>
      </div>
    );
  };
  return (
    <Modal
      title={
        type === ApprovalActions.ACCEPTED
          ? "Accept payment upload"
          : "Reject payment upload"
      }
      setShow={setShow}
      show={show}
      toggle={toggle}
      footerElement={footerButtons()}
      footerClasses="flex items-center justify-center py-[30px] px-[31px] border-t border-t-[#F3F2F2]"
    >
      <TextInput
        label="Comment"
        type="textArea"
        id="commentRejection"
        placeholder="Enter a description"
        rows={5}
        name="comment"
        onInputChange={(_, val) => setReason(val)}
      />
    </Modal>
  );
};

export default UploadApprovalModal;
