import React, { ChangeEvent, FC, useState } from "react";
import TextInput from "../../../TextInput";
import { Icon } from "../../../Icons";
import Text from "../../../Text";
import Button from "../../../Button";
import CardSuccess from "./cardSuccess";

const FundByCard: FC<{ loading: boolean; success: boolean }> = ({
  loading,
  success,
}) => {
  const [cardNumber, setCardNumber] = useState("");
  const [expiry, setExpiry] = useState("");

  const handleCardNumber = (e: ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value;
    if (val) {
      val = val.replace(/ /g, "");
      if (!isNaN(Number(val))) {
        setCardNumber(val.replace(/\d{4}(?=\d)/g, "$& "));
      }
    } else {
      setCardNumber("");
    }
  };

  const handleExpiry = (e: ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    const cleanedString = val?.replace(/\D/g, "");
    if (cleanedString) {
      if (cleanedString.length > 2) {
        // Insert '/' after the first two digits
        setExpiry(cleanedString.slice(0, 2) + "/" + cleanedString.slice(2));
      } else {
        // If the string has less than two digits, return the original string
        setExpiry(cleanedString);
      }
    } else {
      setExpiry("");
    }
  };
  if (success) {
    return <CardSuccess />;
  }

  if (loading) {
    return (
      <div className="flex flex-col items-center mt-6">
        <div className="animate-spin">
          <Icon name="big_loading" />
        </div>
        <Text
          text="Wait a bit we are funding your wallet..."
          size={18}
          color="text-[#000]"
          classNames="font-medium mt-5"
        />
        <div className="flex-1 min-h-[20vh] flex flex-row items-end">
          <Button
            disabled
            label="Secured by Swiftmonie"
            icon="padlock"
            iconPosition="left"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <TextInput
        label="Amount"
        id="amountToFund"
        placeholder="Enter amount to fund"
        name="amount"
        type="number"
      />
      <TextInput
        label="Name on card"
        id="nameOnCard"
        placeholder="Enter name on card"
        name="name"
        type="text"
      />
      <TextInput
        label="Card number"
        id="cardNumber"
        placeholder="1234 1234 1234 1234"
        name="cardNumber"
        type="text"
        prepend
        prependItem={<Icon name="master_card" />}
        onChange={handleCardNumber}
        value={cardNumber}
      />
      <div className="flex flex-row gap-3">
        <TextInput
          label="Expiry"
          id="expiry"
          placeholder="MM/YY"
          name="expiry"
          type="text"
          onChange={handleExpiry}
          value={expiry}
        />
        <TextInput
          label="CVV"
          id="cvv"
          placeholder="***"
          name="cvv"
          type="text"
          // onChange={handleExpiry}
          // value={expiry}
        />
      </div>
    </div>
  );
};
export default FundByCard;
