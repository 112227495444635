import React, { FC } from "react";
import Title from "../Title";
import { IconProps } from "../Icons";
import Otp from "../Otp";
import Button from "../Button";
import InfoAndAction from "../infoAndAction";

interface IAuthVerificationOtpProps {
  title: string;
  description: string;
  icon?: IconProps["name"];
  loading?: boolean;
  handleOtpChange: (val: string) => void;
  handleSubmit: () => void;
  submitLabel: string;
  resendOtp?: () => void;
  showResend?: boolean;
}
const AuthVerificationOtp: FC<IAuthVerificationOtpProps> = ({
  title,
  description,
  icon,
  loading,
  handleOtpChange,
  handleSubmit,
  submitLabel,
  resendOtp,
  showResend = true,
}) => {
  function onOtpChange(val: string) {
    handleOtpChange(val);
  }
  function verify() {
    handleSubmit();
  }
  return (
    <div className="flex flex-col items-center gap-6 w-[100%]">
      <Title
        title={title}
        icon={icon ?? "create_password_icon"}
        description={description}
      />
      <Otp length={6} onChange={onOtpChange} />

      <Button
        variant="primary_lg"
        label={submitLabel}
        classNames="w-[100%]"
        loading={loading}
        onClick={verify}
      />

      {showResend && (
        <InfoAndAction
          info="Didn’t receive an OTP?"
          actionText="Click to resend"
          action={() => (resendOtp ? resendOtp() : null)}
        />
      )}
    </div>
  );
};

export default AuthVerificationOtp;
