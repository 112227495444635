import React, { ReactNode, FC, useMemo, CSSProperties } from "react";

interface ICardProps {
  children: ReactNode;
  padding_x?: string;
  padding_y?: string;
  style?: CSSProperties;
}
const Card: FC<ICardProps> = ({
  children,
  padding_x = "40px",
  padding_y = "28px",
  style = {},
}) => {
  return (
    <div
      className={`mt-6 flex flex-col w-full rounded-xl flex-wrap border border-gray_200 bg-base_White`}
      style={{ padding: `${padding_y} ${padding_x}`, ...style }}
    >
      {children}
    </div>
  );
};

export default Card;
