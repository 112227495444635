import React, { FC } from "react";
import Checkbox from "../../../components/Checkbox";
import Button from "../../../components/Button";
import Text from "../../../components/Text";
import Card from "../../../components/Card";
import Progress from "../../../components/Progress";
import { ILoginResponseData } from "../../../interface/auth";
import { useAppSelector } from "../../../hooks/redux";
import { selectUser } from "../../../redux/features/authSlice";

const Unverified: FC<{ onVerify?: () => void }> = ({ ...props }) => {
  const user = useAppSelector(selectUser);

  return (
    <div className="pb-5">
      <Card>
        <div className="flex flex-col lg:flex-row gap-3 justify-between items-center w-full pb-3 border-b border-gray_200">
          <div className="flex flex-col w-[100%]">
            <Progress />
            <Text
              text="Unlock business account by completing your KYB"
              size={20}
              color="text-gray_900"
              classNames="font-medium"
            />
            <Text
              text="Set up your account to unlock all Swiftmonie features, such as Bulk Payment, and enjoy a seamless financial transaction experience."
              size={14}
              color="text-gray_500"
              classNames=" lg:w-[55%]"
            />
          </div>
          <img src="/images/empty-kyb.svg" alt="..." />
        </div>
        <div className="flex flex-row h-[70px] items-center border-b border-gray_200">
          <Checkbox
            labelStyle={{ textDecoration: "line-through", color: "#343B46" }}
            name=""
            label="Create account"
            checked
            disabled
          />
        </div>
        <div className="flex flex-col lg:flex-row h-[100px] lg:h-[70px] justify-around lg:justify-between items-center border-b border-gray_200">
          <Checkbox
            labelStyle={{ color: "#343B46" }}
            name=""
            label="Verify your business"
          />
          <Button
            label={
              user?.company.active === "REJECTED"
                ? "Update business information"
                : "Start verification"
            }
            variant="primary"
            onClick={props.onVerify ? props.onVerify : () => null}
          />
        </div>
      </Card>
    </div>
  );
};

export default Unverified;
