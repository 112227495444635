import React, { FC, useEffect, useState } from "react";
import Drawer from "../../../components/Drawer";
import UploadComponent from "../../../components/UploadComponent";
import Button from "../../../components/Button";
import Alert from "../../../components/Alert";
import {} from "react-papaparse";
import * as XLSX from "xlsx";
import useToast from "../../../hooks/useToast";
import { IError } from "../../../interface/error";
import api from "../../../network/api";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { downloadFile } from "../../../utils/utilMethods";
import TextInput from "../../../components/TextInput";
import { geturl } from "../../../network/endpoints";

interface IFundWalletDrawerProps {
  toggle: () => void;
  show: boolean;
  title: string;
  callBack?: () => Promise<void>;
}
const UploadPaymentDrawer: FC<IFundWalletDrawerProps> = ({
  toggle,
  show,
  title,
  callBack,
}) => {
  const toast = useToast();

  const [selectedFile, setSelectedFile] = useState<string>();
  const [rawFile, setRawFile] = useState<File>();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [uploadTitle, setUploadTitle] = useState("");

  useEffect(() => {
    if (!show) {
      setUploadTitle("");
    }
  }, [show]);

  const handleFileChange = (file: File) => {
    setError(false);
    setRawFile(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const csvData = XLSX.utils.sheet_to_json(worksheet);

      for (const i in csvData) {
        const csv = csvData[i];
        const validationData = csv as {
          Account: string | number;
          "Account Name": string;
          Amount: string | number;
          "Bank Name": string;
          Narration: string;
          "Trans Date": string;
        };

        if (
          !validationData.Account ||
          !validationData["Account Name"] ||
          !validationData.Amount ||
          !validationData["Bank Name"]
        ) {
          toast.error(` Cross check EXCEL file on row ${i + 1}`);
          setError(true);
          setSelectedFile(undefined);
          return;
        }

        if (validationData.Account.toString().length !== 10) {
          toast.error(
            `Account number on row ${i + 2} is invalid cross check EXCEL file`
          );
          setError(true);
          setSelectedFile(undefined);

          return;
        }
      }
    };
    reader.readAsArrayBuffer(file);

    const base64Reader = new FileReader();
    base64Reader.onload = (event) => {
      setSelectedFile(base64Reader.result as string);
    };
    base64Reader.readAsDataURL(file);
  };

  const submit = async () => {
    try {
      setLoading(true);
      const response = await api.uploadPayment({
        title: uploadTitle,
        batchId: uuid(),
        fileContentType: rawFile?.type ?? "",
        fileName: rawFile?.name ?? "",
        base64File: selectedFile ?? "",
        settlementDate: moment(new Date()).format("YYYY-MM-DD"),
      });

      setSelectedFile(undefined);
      setRawFile(undefined);
      if (callBack) callBack();
      toggle();
    } catch (err) {
      const error = err as IError;
      toast.error(error.message || "An error occured");
    }
    setLoading(false);
  };

  return (
    <Drawer
      toggle={toggle}
      show={show}
      title={title}
      showFooterButton
      footerButton={
        <div className="m-auto">
          <Button
            label="Send for review"
            variant="primary"
            classNames="w-[179px]"
            onClick={submit}
            disabled={!selectedFile || error || !uploadTitle}
            loading={loading}
            loadingText="Loading..."
          />
        </div>
      }
    >
      <div className="px-[15px] py-5">
        <div className="mb-2">
          <TextInput
            label="Title"
            id="uploadTitle"
            placeholder="E.g. Salary"
            name="title"
            onInputChange={(_, val) => setUploadTitle(val)}
            defaultValue={uploadTitle ?? ""}
          />
        </div>

        <UploadComponent
          title="Upload payees payment details"
          description="No file added"
          accept=".xlsx"
          onSingleFileChange={handleFileChange}
          selectedFile={rawFile}
          info={
            <Alert
              type="info"
              text="Please use the provided template to submit your payee's information and ensure the account number column is a text field. This will help prevent delays or issues with your transactions. Thank you for your cooperation."
              button={
                <Button
                  label="Download payment template"
                  icon="arrow_right"
                  iconPosition="right"
                  variant="link"
                  onClick={() =>
                    downloadFile(
                      `${geturl()}/swiftmonie-backend/api/v1/public/template/download`
                    )
                  }
                />
              }
            />
          }
        />
        {!error ? undefined : (
          <div className="mt-[24px]">
            <Alert
              type="error"
              text="Please check the EXCEL file. Some field are empty or doesn’t match"
            />
          </div>
        )}
      </div>
    </Drawer>
  );
};
export default UploadPaymentDrawer;
