import React, { FC, useEffect, useState } from "react";
import Drawer from "../../../components/Drawer";
import Text from "../../../components/Text";
import StatusComponent from "../../../components/StatusComponent";
import UploadComponent, {
  UploadedFile,
} from "../../../components/UploadComponent";
import StepIndicator from "../../../components/StepIndicator";
import { Icon } from "../../../components/Icons";
import Button from "../../../components/Button";
import { useAppSelector } from "../../../hooks/redux";
import {
  clientRoles,
  selectRoleAndPermission,
} from "../../../redux/features/authSlice";
import { useNavigate } from "react-router-dom";
import { UPLOADS } from "../../../routes/navigations";
import {
  ApprovalActions,
  IGetUploads,
  IUploadCommentsResponseData,
  SuccessStatusEnum,
} from "../../../interface/upload";
import {
  capitalize,
  downloadFile,
  formatDate,
  removeUnderscore,
} from "../../../utils/utilMethods";
import useToast from "../../../hooks/useToast";
import * as XLSX from "xlsx";
import { handleError } from "../../../utils/error";
import api from "../../../network/api";
import moment from "moment";
import UploadApprovalModal from "../../../components/UploadApprovalModal";
import CommentItem from "./commentItem";
import { BulletListLoader } from "../../../components/Loaders";

interface IViewUploadProps {
  title: string;
  toggle: () => void;
  show: boolean;
  upload: IGetUploads["content"][0];
  callBack?: () => void;
}

const ViewUpload: FC<IViewUploadProps> = ({
  show,
  toggle,
  title,
  upload,
  callBack,
}) => {
  const toast = useToast();
  const [rawFile, setRawFile] = useState<File>();
  const [selectedFile, setSelectedFile] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const navigate = useNavigate();
  const userRoleAndPermission = useAppSelector(selectRoleAndPermission);
  const [showActionModal, setShowActionModal] = useState(false);
  const [actionType, setActionType] = useState<ApprovalActions | undefined>(
    ApprovalActions.REJECTED
  );
  const [groupedComments, setGroupedComments] =
    useState<Record<string, Array<IUploadCommentsResponseData>>>();

  const [commentLoading, setCommentLoading] = useState(false);

  const toggleActionModal = () => setShowActionModal((prev) => !prev);

  useEffect(() => {
    if (show) getComments();
  }, [show]);

  const checkIfApprovalButtonCanShow = () => {
    if (!upload) return false;
    if (
      Object.values(SuccessStatusEnum).includes(
        upload.status as SuccessStatusEnum
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const getComments = async () => {
    try {
      setGroupedComments(undefined);
      setCommentLoading(true);
      const response = await api.viewUploadComments(upload.id);
      if (response) {
        let groupedResponse: Record<
          string,
          Array<IUploadCommentsResponseData>
        > = {};
        Object.values(response)
          .filter((res) => typeof res !== "number")
          .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
          .forEach((res) => {
            if (!groupedResponse[formatDate(res.createdAt, "YYYY-MM-DD")]) {
              groupedResponse = {
                ...groupedResponse,
                [formatDate(res.createdAt, "YYYY-MM-DD")]: [res],
              };
            } else if (
              groupedResponse[formatDate(res.createdAt, "YYYY-MM-DD")]
            ) {
              const value = [
                ...groupedResponse[formatDate(res.createdAt, "YYYY-MM-DD")],
                res,
              ];

              groupedResponse[formatDate(res.createdAt, "YYYY-MM-DD")] = value;
            }
          });

        setGroupedComments(groupedResponse);
      }
    } catch (error) {
      handleError(error, toast);
    }
    setCommentLoading(false);
  };

  const getFileName = (file: string) => {
    if (!file) return "";
    const fileArr = file.split(".");
    return fileArr[fileArr.length - 1];
  };

  const handleFileChange = (file: File) => {
    setRawFile(file);
    setError(false);
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const csvData = XLSX.utils.sheet_to_json(worksheet);

      for (const i in csvData) {
        const csv = csvData[i];
        const validationData = csv as {
          Account: string | number;
          "Account Name": string;
          Amount: string | number;
          "Bank Name": string;
          Narration: string;
          "Trans Date": string;
        };

        if (
          !validationData.Account ||
          !validationData["Account Name"] ||
          !validationData.Amount ||
          !validationData["Bank Name"]
        ) {
          toast.error(` Cross check EXCEL file on row ${i + 1}`);
          setError(true);
          setSelectedFile(undefined);
          return;
        }

        if (validationData.Account.toString().length !== 10) {
          toast.error(
            `Account number on row ${i + 2} is invalid cross check EXCEL file`
          );
          setError(true);
          setSelectedFile(undefined);

          return;
        }
      }
    };
    reader.readAsArrayBuffer(file);

    const base64Reader = new FileReader();
    base64Reader.onload = (event) => {
      setSelectedFile(base64Reader.result as string);
    };
    base64Reader.readAsDataURL(file);
  };

  const removeHandle = () => {
    setRawFile(undefined);
    setSelectedFile(undefined);
  };

  const resendUpload = async () => {
    try {
      setLoading(true);
      await api.resendUpload({
        batchId: upload.batchId,
        fileContentType: rawFile?.type ?? "",
        fileName: rawFile?.name ?? "",
        title: upload.title,
        base64File: selectedFile ?? "",
        settlementDate: moment(new Date()).format("YYYY-MM-DD"),
      });
      setSelectedFile(undefined);
      setRawFile(undefined);
      if (callBack) callBack();
      toggle();
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };

  const handleClose = () => {
    removeHandle();
    toggle();
  };

  const actionCallBack = () => {
    if (callBack) callBack();
    toggle();
  };
  const getFooterButton = () => {
    if (!checkIfApprovalButtonCanShow()) return null;
    if (
      (userRoleAndPermission?.role === clientRoles.approver ||
        userRoleAndPermission?.role === clientRoles.reviewer) &&
      !selectedFile
    ) {
      return (
        <div className="flex flex-row mx-auto gap-3">
          <Button
            label="Reject"
            classNames="min-w-[179px]"
            onClick={toggleActionModal}
            style={{ borderColor: "#FFAC9D", color: "#C82A0D" }}
          />
          <Button
            label="Accept"
            variant="primary"
            classNames="min-w-[179px]"
            onClick={() => navigate(`${UPLOADS}/${upload.batchId}`)}
          />
        </div>
      );
    }
    return (
      <div className="flex flex-row mx-auto gap-3">
        <Button
          label="Close"
          classNames="min-w-[179px]"
          onClick={handleClose}
        />
        <Button
          label="Resend for review"
          variant="primary"
          disabled={!selectedFile || error}
          classNames="min-w-[179px]"
          onClick={resendUpload}
          loading={loading}
        />
      </div>
    );
  };
  return (
    <Drawer
      title={title}
      show={show}
      toggle={toggle}
      footerButton={getFooterButton()}
      showFooterButton
    >
      <div className="px-[15px] py-5 overflow-y-auto">
        <div className="flex flex-col">
          <div className="py-[10px] flex flex-row justify-between">
            <Text
              size={14}
              text="Status"
              classNames="font-medium"
              color="text-[#252C32]"
            />
            <StatusComponent status={upload?.status ?? ""} />
          </div>
          <div className="py-[10px] flex flex-row justify-between">
            <Text
              size={14}
              text="Uploaded by"
              classNames="font-medium"
              color="text-[#252C32]"
            />
            <Text
              text={upload?.addedBy ?? ""}
              size={14}
              color="text-[#252C32]"
            />
          </div>
          <div className="py-[10px] flex flex-row justify-between">
            <Text
              size={14}
              text="Date and Time"
              classNames="font-medium"
              color="text-[#252C32]"
            />
            <Text
              text={formatDate(upload?.createdAt, "DD MMMM YYYY, HH:mm:ss")}
              size={14}
              color="text-[#252C32]"
            />
          </div>
          <div className="flex flex-col gap-[10px] mt-3">
            <Text
              text="Uploaded file"
              size={14}
              classNames="font-medium"
              color="text-[#252C32]"
            />
            {upload && (
              <UploadedFile
                name={upload.title}
                fileType={getFileName(upload?.filePath) as unknown as "jpg"}
                size=""
                onClickAction={() => downloadFile(upload.filePath)}
              />
            )}
          </div>
          {userRoleAndPermission?.role === clientRoles.initiator &&
            !Object.values(SuccessStatusEnum).includes(
              upload.status as SuccessStatusEnum
            ) && (
              <div className="mt-3">
                <UploadComponent
                  uploadButtonText="Click to reupload"
                  onSingleFileChange={handleFileChange}
                  selectedFile={rawFile}
                  onRemove={removeHandle}
                  accept=".xlsx"
                />
              </div>
            )}
        </div>

        {commentLoading ? (
          <BulletListLoader />
        ) : (
          <div className="mt-1">
            {groupedComments && Object.values(groupedComments).length > 0 && (
              <Text
                text="Comments"
                size={14}
                classNames="font-medium mb-2"
                color="text-[#252C32]"
              />
            )}
            {groupedComments &&
              Object.keys(groupedComments).map((date, i) => (
                <div className="flex flex-col items-stretch" key={i}>
                  {/* date */}

                  <div className="flex flex-col">
                    <div className="flex flex-row items-center gap-1">
                      <div className="h-[14px] w-[14px] bg-base_white border-4 border-[#CFD4DC] rounded-xl flex items-center justify-center" />
                      <Text text={date} size={12} color="text-[#252C32]" />
                    </div>
                    <div
                      className={`h-[30px] w-[1px]  bg-[#E4E7ED] rounded-[1.16px] ml-[6px]`}
                    />
                  </div>

                  {/* comment */}
                  {groupedComments[date].length > 0 &&
                    groupedComments[date].map((data) => (
                      <CommentItem
                        key={data.id}
                        title={`${data.addedBy} (${capitalize(removeUnderscore(data?.approvalStageRole?.description))})`}
                        comment={data.comment}
                      />
                    ))}
                </div>
              ))}
          </div>
        )}

        {actionType && upload.batchId && (
          <UploadApprovalModal
            toggle={toggleActionModal}
            setShow={setShowActionModal}
            show={showActionModal}
            type={actionType}
            batchId={upload.batchId}
            callBack={actionCallBack}
          />
        )}
      </div>
    </Drawer>
  );
};

export default ViewUpload;
