import { StatusComponentItem } from "../components/StatusComponent";
import React from "react";
import { capitalize } from "../utils/utilMethods";
export const useRoleElement = () => {
  const getRoleElement = (text: string) => {
    switch (text.toLowerCase()) {
      case "initiator":
        return (
          <StatusComponentItem
            showIcon={false}
            color="text-primary_Blue_700"
            background="bg-primary_Blue_50"
            text={capitalize(text?.replace(/_/, " "))}
          />
        );
      case "super_admin":
        return (
          <StatusComponentItem
            showIcon={false}
            color="text-[#5925DC]"
            background="bg-[#F4F3FF]"
            text={capitalize(text?.replace(/_/, " "))}
          />
        );
      case "approver":
        return (
          <StatusComponentItem
            showIcon={false}
            color="text-[#C11574]"
            background="bg-[#FDF2FA]"
            text={capitalize(text)}
          />
        );
      case "reviewer":
        return (
          <StatusComponentItem
            showIcon={false}
            color="text-[#ffffff]"
            background="bg-gray_900"
            text={capitalize(text)}
          />
        );
    }
  };

  return {
    getRoleElement,
  };
};
