import React, { FC } from "react";
import { Icon } from "../../../components/Icons";
import Text from "../../../components/Text";

const CommentItem: FC<{ title: string; comment: string }> = ({
  title = "Bello Shehu (Reviewer)",
  comment,
}) => {
  return (
    <div className="flex flex-col relative">
      <div className="flex flex-row items-center gap-1">
        <div className="-ml-[2px] h-[18px] w-[18px] bg-primary_Blue_600 border border-primary_Blue_600 rounded-xl flex items-center justify-center">
          <Icon name="done_check" />
        </div>
        <div className="absolute left-5">
          <Text
            text={title}
            size={14}
            classNames="font-medium"
            color="text-[#252C32]"
          />
          <Text text={comment} size={14} color="text-[#252C32]" />
        </div>
      </div>
      <div
        className={`h-[30px] w-[1px]  bg-primary_Blue_600 rounded-[1.16px] ml-[6px]`}
      />
    </div>
  );
};

export default CommentItem;
