export const MAX_FILE_SIZE = 2000000;
export const MAX_MULTIPLE_FILE_SIZE = 20000000;

export const businessTypes = [
  { label: "Partnership", value: "PARTNERSHIP" },
  { label: "Limited Liability", value: "LIMITED_LIABILITY" },
  {
    label: "Public Liability company",
    value: "PUBLIC_LIMITED_LIABILITY",
  },
  {
    label: "Sole Proprietorship",
    value: "SOLE_PROPRIETORSHIP",
  },
];

export const businessSectors = [
  {
    label: "Agriculture, forestry and fishing",
    value: "Agriculture, forestry and fishing",
  },
  { label: "Mining and quarrying", value: "Mining and quarrying" },
  { label: "Manufacturing", value: "Manufacturing" },
  {
    label: "Electricity, gas, steam and air conditioning supply",
    value: "Electricity, gas, steam and air conditioning supply",
  },
  {
    label:
      "Water supply; sewerage, waste management and remediation activities",
    value:
      "Water supply; sewerage, waste management and remediation activities",
  },
  { label: "Construction", value: "Construction" },
  {
    label:
      "Wholesale and retail trade; repair of motor vehicles and motorcycles",
    value:
      "Wholesale and retail trade; repair of motor vehicles and motorcycles",
  },
  { label: "Transportation and storage", value: "Transportation and storage" },
  {
    label: "Accommodation and food service activities",
    value: "Accommodation and food service activities",
  },
  {
    label: "Information and communication",
    value: "Information and communication",
  },
  {
    label: "Financial and insurance activities",
    value: "Financial and insurance activities",
  },
  { label: "Real estate activities", value: "Real estate activities" },
  {
    label: "Professional, scientific and technical activities",
    value: "Professional, scientific and technical activities",
  },
  {
    label: "Administrative and support service activities",
    value: "Administrative and support service activities",
  },
  {
    label: "Public administration and defence; compulsory social security",
    value: "Public administration and defence; compulsory social security",
  },
  { label: "Education", value: "Education" },
  {
    label: "Human health and social work activities",
    value: "Human health and social work activities",
  },
  {
    label: "Arts, entertainment and recreation",
    value: "Arts, entertainment and recreation",
  },
  { label: "Other service activities", value: "Other service activities" },
  {
    label:
      "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use",
    value:
      "Activities of households as employers; undifferentiated goods- and services-producing activities of households for own use",
  },
  {
    label: "Activities of extraterritorial organizations and bodies",
    value: "Activities of extraterritorial organizations and bodies",
  },
];
