import React from "react";
import { Icon } from "../../components/Icons";
import Text from "../../components/Text";

const EmptyNotification = () => {
  return (
    <div className="flex flex-col gap-3 items-center mt-2">
      <Icon name="notification" />

      <Text
        text="No notifications for now"
        size={18}
        color="text-secondary"
        classNames="font-medium"
      />
    </div>
  );
};

export default EmptyNotification;
