import storage from "react-secure-storage";

export enum storageKey {
  AUTH_TOKEN = "AUTH_TOKEN",
  BUSINESS_UPLOADS = "BUSINESS_UPLOADS",
  BUSINESS_DETAILS = "BUSINESS_DETAILS",
  DIRECTOR_DETAILS = "DIRECTOR_DETAILS",
  BUSINESS_STATUS = "BUSINESS_STATUS",
  ROLE = "ROLE",
}
export const setItem = (key: storageKey, data: string): void => {
  storage.setItem(key, data);
};

export const getItem = (key: storageKey) => {
  const savedData = storage.getItem(key);
  if (!savedData) return;
  return savedData;
};

export const removeItem = (key: string): void => {
  storage.removeItem(key);
};

export const clear = () => storage.clear();
