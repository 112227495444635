// import { Suspense } from "react";
import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import PublicContainer from "../Container/publicContainer";
import publicRoutes from "./public";
import Main from "../Container/main";
import privateRoutes from "./private";
import verificationRoutes from "./verificationRoutes";
import VerificationContainer from "../Container/verificationContainer";
import ReviewPaymentContainer from "../Container/reviewPaymentContainer";
import reviewUploadRoute from "./uploadReviewRoute";
import SuspenseLoader from "../components/SuspenseLoader";

const Routing = () => {
  return (
    <Suspense fallback={<SuspenseLoader />}>
      <Routes>
        <Route path="/" element={<Main />}>
          {privateRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            );
          })}
        </Route>

        <Route path="/" element={<VerificationContainer />}>
          {verificationRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            );
          })}
        </Route>

        <Route path="/" element={<ReviewPaymentContainer />}>
          {reviewUploadRoute.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            );
          })}
        </Route>

        <Route path="/" element={<PublicContainer />}>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={<route.element />}
                key={index}
              />
            );
          })}
        </Route>

        {/* <Route path="*" element={<NotFound />} /> */}
      </Routes>
    </Suspense>
  );
};

export default Routing;
