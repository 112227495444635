import React, { FC, useState } from "react";
import EmptyNotification from "./emptyNotification";
import Text from "../../components/Text";
import Drawer from "../../components/Drawer";
import Button from "../../components/Button";
import { BulletListLoader } from "../../components/Loaders";
import { IGetNotification } from "../../interface/notification";
import { formatDate } from "../../utils/utilMethods";
import useToast from "../../hooks/useToast";
import { handleError } from "../../utils/error";
import api from "../../network/api";
import ViewNotificationModal from "./viewNotificationModal";
import { PropagateLoader } from "react-spinners";
import { useAppSelector } from "../../hooks/redux";
import { selectUser } from "../../redux/features/authSlice";

interface INotification {
  toggle: () => void;
  show: boolean;
  loading: boolean;
  notifications: IGetNotification[];
  callBack: (asCallBack?: boolean) => void;
}

const NotificationItem: FC<{
  notification: IGetNotification;
  callBack: (asCallBack?: boolean) => void;
}> = ({ notification, callBack }) => {
  const toast = useToast();

  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState<IGetNotification>();
  const [loading, setLoading] = useState(false);

  const toggleDetails = () => {
    setShowDetails((prev) => !prev);
  };

  const readNotification = async (id: number) => {
    setDetails(undefined);
    try {
      setLoading(true);
      const response = await api.readNotification(id);
      setDetails(response);
      toggleDetails();
      if (callBack) callBack(true);
    } catch (error) {
      handleError(error, toast);
    }
    setLoading(false);
  };
  return (
    <>
      <div
        className=" px-[26px] py-[10px] flex flex-col gap-[12px] border-b border-gray_100 cursor-pointer"
        onClick={() => readNotification(notification.id)}
      >
        <div className="h-[47px] py-[9px] px-0 flex flex-row gap-[12px] justify-between items-center">
          {loading ? (
            <div className="text-center flex flex-row justify-center w-[100%]">
              <PropagateLoader color="#0676FF" />
            </div>
          ) : (
            <div className="flex flex-row gap-3">
              <img
                src="/images/icons/notificationAvatar.svg"
                alt="notification"
              />
              <div>
                <Text
                  text={
                    notification.messageValue
                      ? JSON.parse(notification.messageValue).subject
                      : ""
                  }
                  size={16}
                  color="text-[#283C46]"
                />
                <Text
                  text={formatDate(notification.updatedAt, "MMM DD, YYYY")}
                  size={12}
                  color="text-[#6D6D6D]"
                />
              </div>
            </div>
          )}

          {notification.status !== "READ" && (
            <div className="min-w-[10px] h-[10px] rounded-md bg-[#FF5400]" />
          )}
        </div>
      </div>
      {details && (
        <ViewNotificationModal
          show={showDetails}
          setShow={setShowDetails}
          toggle={toggleDetails}
          title={
            details?.messageValue
              ? JSON.parse(details?.messageValue).subject
              : ""
          }
          message={
            details?.messageValue
              ? JSON.parse(details?.messageValue).subject
              : ""
          }
        />
      )}
    </>
  );
};

const Notifications: FC<INotification> = ({
  toggle,
  notifications,
  show,
  loading,
  callBack,
}) => {
  const toast = useToast();
  const user = useAppSelector(selectUser);
  const [readingAll, setReadingAll] = useState(false);

  const readAll = async () => {
    if (!notifications.some((notification) => notification.status !== "READ")) {
      toast.success("Done");
      return;
    }
    try {
      setReadingAll(true);
      if (!user) return;
      await api.readAllNotification(user.company?.id);
      toast.success("Marked all messages as read");
      if (callBack) callBack();
    } catch (error) {
      toast.error("An error occured");
    }
    setReadingAll(false);
  };
  return (
    <Drawer
      toggle={toggle}
      show={show}
      title="Notifications"
      showFooterButton={notifications.length ? true : false}
      footerButton={
        <div className="m-auto">
          <Button
            label="Mark all as read"
            loading={readingAll}
            onClick={readAll}
            variant="primary_lg"
          />
        </div>
      }
    >
      {loading && <BulletListLoader />}
      {notifications.length === 0 && !loading && <EmptyNotification />}
      {notifications.length > 0 && !loading && (
        <div className="h-[100%] overflow-y-auto">
          {notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              callBack={callBack}
            />
          ))}
        </div>
      )}
    </Drawer>
  );
};

export default Notifications;
