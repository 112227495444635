import React from "react";
import Card from "../../../components/Card";
import { Icon } from "../../../components/Icons";
import Text from "../../../components/Text";
import StatusComponent from "../../../components/StatusComponent";

const VerificationInProgress = () => {
  return (
    <Card>
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-row gap-3">
          <Icon name="verification" />
          <div>
            <Text
              text="We’re verifying your business"
              classNames="font-medium"
              color="text-gray_900"
              size={18}
            />
            <Text
              size={14}
              text="We’ll notify you within 24 hours once your business verification is completed."
            />
          </div>
        </div>

        <StatusComponent status="in review" />
      </div>
    </Card>
  );
};

export default VerificationInProgress;
