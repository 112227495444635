import Register from "../Screens/register";
import Createpassword from "../Screens/register/createPassword";
import VerifyEmail from "../Screens/register/verifyEmail";
import Login from "../Screens/auth/login";
import {
  CREATE_PASSWORD,
  FORGOTPASSWORD,
  LOGIN,
  REGISTER,
  SET_NEW_PASSWORD,
  VERIFICATION,
  VERIFY_EMAIL,
} from "./navigations";
import Verification from "../Screens/auth/verification";
import ForgotPassword from "../Screens/auth/forgotPassword";
import SetNewPassword from "../Screens/auth/setNewPassword";

const publicRoutes = [
  { path: REGISTER, element: Register },
  { path: VERIFY_EMAIL, element: VerifyEmail },
  { path: CREATE_PASSWORD, element: Createpassword },
  { path: LOGIN, element: Login },
  { path: VERIFICATION, element: Verification },
  { path: FORGOTPASSWORD, element: ForgotPassword },
  { path: SET_NEW_PASSWORD, element: SetNewPassword },
];

export default publicRoutes;
