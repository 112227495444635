import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import {
  IBusinessDetails,
  IBusinessUploads,
  IDirectorData,
} from "../../interface/business";
import { getItem, removeItem, setItem, storageKey } from "../../utils/storage";
import { IRoleData } from "../../interface/auth";
import { IGetNotificationSettingsData } from "../../interface/notification";
import { IWalletDashboardResponseData } from "../../interface/wallet";

// Define a type for the slice state
interface GlobalState {
  loading: boolean;
  businessDetails: IBusinessDetails | null;
  directorDetails: IDirectorData[];
  businessUploads: Array<IBusinessUploads>;
  businessInReview?: boolean;
  availableRoles: Array<IRoleData>;
  notificationSettings: IGetNotificationSettingsData | null;
  walletStat: IWalletDashboardResponseData | null;
}
const businessDetails = getItem(storageKey.BUSINESS_DETAILS) as string;
const businessUploads = localStorage.getItem(
  storageKey.BUSINESS_UPLOADS
) as string;
const directorDetails = getItem(storageKey.DIRECTOR_DETAILS) as string;
const businessInReview = getItem(storageKey.BUSINESS_STATUS) as string;

// Define the initial state using that type
const initialState: GlobalState = {
  loading: false,
  businessDetails: businessDetails ? JSON.parse(businessDetails) : null,
  directorDetails: directorDetails ? JSON.parse(directorDetails) : [],
  businessUploads: businessUploads ? JSON.parse(businessUploads) : [],
  businessInReview: businessInReview ? JSON.parse(businessInReview) : false,
  availableRoles: [],
  notificationSettings: null,
  walletStat: null,
};

export const globalSlice = createSlice({
  name: "global",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setGlobalLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBusinessDetails: (
      state,
      action: PayloadAction<GlobalState["businessDetails"]>
    ) => {
      state.businessDetails = action.payload;
      setItem(storageKey.BUSINESS_DETAILS, JSON.stringify(action.payload));
    },
    setBusinessUploads: (
      state,
      action: PayloadAction<GlobalState["businessUploads"]>
    ) => {
      state.businessUploads = action.payload;
      localStorage.setItem(
        storageKey.BUSINESS_UPLOADS,
        JSON.stringify(action.payload)
      );
    },
    setDirectorDetails: (
      state,
      action: PayloadAction<GlobalState["directorDetails"]>
    ) => {
      state.directorDetails = action.payload;
      setItem(storageKey.DIRECTOR_DETAILS, JSON.stringify(action.payload));
    },
    clearBusinessInfo: (state) => {
      removeItem(storageKey.BUSINESS_DETAILS);
      localStorage.removeItem(storageKey.BUSINESS_UPLOADS);
      removeItem(storageKey.DIRECTOR_DETAILS);
      state.businessDetails = null;
      state.businessUploads = [];
      state.directorDetails = [];
    },
    setBusinessStatus: (state, action: PayloadAction<boolean>) => {
      state.businessInReview = action.payload;
      if (action.payload === false) {
        removeItem(storageKey.BUSINESS_STATUS);
      } else {
        setItem(storageKey.BUSINESS_STATUS, action.payload.toString());
      }
    },

    setAvailableRoles: (state, action: PayloadAction<IRoleData[]>) => {
      state.availableRoles = action.payload;
    },
    setNotificationSettings: (
      state,
      action: PayloadAction<IGetNotificationSettingsData>
    ) => {
      state.notificationSettings = action.payload;
    },
    setWalletStat: (
      state,
      action: PayloadAction<IWalletDashboardResponseData>
    ) => {
      state.walletStat = action.payload;
    },
    resetGlobalState: (state) => {
      return { ...initialState };
    },
  },
});

export const {
  setGlobalLoading,
  setBusinessDetails,
  setBusinessUploads,
  setDirectorDetails,
  clearBusinessInfo,
  setBusinessStatus,
  setAvailableRoles,
  setNotificationSettings,
  setWalletStat,
  resetGlobalState,
} = globalSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getLoading = (state: RootState) => state.global.loading;
export const getBusinessDetails = (state: RootState) =>
  state.global.businessDetails;
export const getBusinessUploads = (state: RootState) =>
  state.global.businessUploads;

export const getDirectorDetails = (state: RootState) =>
  state.global.directorDetails;

export const getBusinessInReviewStatus = (state: RootState) =>
  state.global.businessInReview;

export const getAvailableRoles = (state: RootState) =>
  state.global.availableRoles;

export const getNotificationSettings = (state: RootState) =>
  state.global.notificationSettings;

export const selectWalletStat = (state: RootState) => state.global.walletStat;

export default globalSlice.reducer;
