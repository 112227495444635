export const REGISTER = "/register";
export const LOGIN = "/login";
export const DASHBOARD = "/";
export const WALLET = "/wallet";
export const TRANSACTIONS = "/transactions";
export const REPORTS = "/reports";
export const TEAM = "/team";
export const HELP_AND_SUPPORT = "/help-support";
export const SETTINGS = "/settings";
export const VERIFY_EMAIL = "/verifyemail";
export const CREATE_PASSWORD = "/createpassword";
export const VERIFICATION = "/verification";
export const FORGOTPASSWORD = "/forgotpassword";
export const SET_NEW_PASSWORD = "/setnewpassword";
export const FUND_WALLET = "/fund-wallet";
export const BUSINESS_DETAILS = "/business-details";
export const DIRECTOR_DETAILS = "/director-details";
export const BUSINESS_DOCUMENTS = "/business-documents";
export const REVIEW_BUSINESS_INFO = "/review-business-info";
export const BUSINESS_SUCCESS = "/business-success";
export const UPLOADS = "/uploads";
